import React, {useEffect, useState, useContext} from 'react';
import classNames from 'classnames/bind';
import styles from './FinanceQuizz.module.scss';
import {NavLink, useMatch} from 'react-router-dom';
import Header from "../../components/Header/Header";
import financeStrips from '../../assets/images/Header/finance-quizzes-strips.png';
import {quizzQuestions} from "../../data/financeQuizzes";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import orangeCross from '../../assets/images/FinanceQuizzes/orangeCross.svg';

const cn = classNames.bind(styles);

const FinanceQuizz = () => {
    const [questionItem, setQuestionItem] = useState(null);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const {params} = useMatch('finance-quizzes/:quizzNumber');

    useEffect(() => {
        const currentQuestion = quizzQuestions.filter(i => i.id === +params?.quizzNumber);
        setQuestionItem(currentQuestion[0]);
    }, [params]);

    return (
        <div className={cn('wrapper')}>
            <Header background="#EE7D00" text="Финансовые загадки" strips={financeStrips}/>
            <div className={cn('content')}>
                <h2 className={cn('title')}>Загадка {questionItem?.id}</h2>
                <div className={cn('flex-wrapper')}>
                    <p className={cn('text')}>{questionItem?.text}</p>
                    <img src={questionItem?.image} alt=""/>
                </div>
                <div className={cn('buttons')}>
                    <button className={cn('btn', 'btn-red')} onClick={() => setIsModalOpened(true)}>
                        Сдаюсь. Скажите правильный ответ!
                    </button>
                    <button className={cn('btn', 'btn-green')} onClick={() => setIsModalOpened(true)}>
                        Я знаю ответ! <br/> Готов проверить себя!
                    </button>
                </div>
            </div>
            <ModalWindow windowState={isModalOpened} setModal={setIsModalOpened} cross={orangeCross}
                         stripColor="#EF7D00">
                <div className={cn('modal-container')}>
                    <img className={cn('qr')} src={questionItem?.qr} alt="qr"/>
                    <p className={cn('modal-text')}>Чтобы узнать правильный ответ, наведите камеру телефона на
                        QR-код</p>
                    <NavLink className={cn('modal-btn')} to="/finance-quizzes">Другие загадки</NavLink>
                </div>
            </ModalWindow>
        </div>

    );
};

export default FinanceQuizz;