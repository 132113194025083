import React from 'react';
import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import arrow from '../../assets/images/Header/arrow.svg'
import {NavLink} from "react-router-dom";

const cn = classNames.bind(styles);

const Header = ({background, text, strips}) => {
    return (
        <header className={cn('header')} style={{background: background}}>
            <p className={cn('text')}>{text}</p>
            <div className={cn('right-block')}>
                <img src={strips || ''} alt=""/>
                <NavLink to="/" className={cn('button')}>
                    <img src={arrow} alt=""/>
                    <span>Меню</span>
                </NavLink>
            </div>
        </header>
    );
};

export default Header;