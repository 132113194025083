import React, {useState} from 'react';
import {createContext} from "react";

export const BadFinanceHabitsContext = createContext();

const FinanceHabitsContext = ({children}) => {
    const [totalScore, setTotalScore] = useState(0);

    const increaseScore = (points) => setTotalScore(prev => prev += points);
    const decreaseScore = (points) => setTotalScore(prev => prev -= points);
    const clearScore = () => setTotalScore(0);

    return (
        <>
            <BadFinanceHabitsContext.Provider
                value={{
                    totalScore,
                    increaseScore,
                    decreaseScore,
                    clearScore
                }}>
                {children}
            </BadFinanceHabitsContext.Provider>
        </>
    );
};

export default FinanceHabitsContext;