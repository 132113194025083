import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames/bind';
import styles from './FinanceHabitsResult.module.scss';
import Header from "../../components/Header/Header";
import financeHabitsStrips from '../../assets/images/Header/finance-habits-strips.svg';
import {useNavigate} from "react-router-dom";
import {financeHabitsResult} from "../../data/financeHabits";
import {BadFinanceHabitsContext} from "../../context/financeHabitsContext";
import resultImg from '../../assets/images/FinanceHabits/new-result-image.svg';
import html2pdf from "html2pdf.js/src";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import greenCross from "../../assets/images/Keyboard/green-cross.svg";
import EmailModal from "../../components/EmailModal/EmailModal";
import KeyBoard from "../../components/KeyBoard/KeyBoard";

const cn = classNames.bind(styles);


const FinanceHabitsResult = () => {
    const [resultItem, setResultItem] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [isEmailOpened, setIsEmailOpened] = useState(false);
    const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [generatedPDF, setGeneratedPDF] = useState("");
    const [typeKeyboard, setTypeKeyboard] = useState('');
    const {totalScore, clearScore} = useContext(BadFinanceHabitsContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (totalScore >= 0 && totalScore <= 8) setResultItem(financeHabitsResult[0])
        if (totalScore >= 9 && totalScore <= 17) setResultItem(financeHabitsResult[1])
        if (totalScore >= 18 && totalScore <= 26) setResultItem(financeHabitsResult[2])
        if (totalScore >= 27 && totalScore <= 34) setResultItem(financeHabitsResult[3])
    }, []);

    const generatePDF = async () => {
        const element = document.getElementById('pdf-content');
        const opt = {
            margin:       [1,0,0,0],
            filename:     'document.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'px', format: [500,665] }
        };
       const res = await html2pdf().set(opt).from(element).output('datauristring');
       const finalRes = res.split(',')[1];
       setGeneratedPDF(finalRes);
       //const res = html2pdf().set(opt).from(element).output('dataurlnewwindow');
    }

    const openModalWindow = () => {
        generatePDF().then(() => setIsEmailOpened(true));
    }

    return (
        <div className={cn('wrapper')}>
            <Header background="#00A555" text={"Трекер вредных \n финансовых привычек"}
                    strips={financeHabitsStrips}/>
            <div className={cn('content')}>
                <div className={cn('flex-container')}>
                    <div className={cn('left-block')}>
                        <p className={cn('top-text')}>Результат:</p>
                        <h2 className={cn('title')}>{resultItem?.title}</h2>
                        <p className={cn('description')}>{resultItem?.description}</p>
                        <div className={cn('list')}>
                            <p className={cn('list-title')}>{resultItem?.firstMessage?.messageTitle}</p>
                            <ul className={cn('list-container')}>
                                {resultItem?.firstMessage?.advices.map(i => <li>{i}</li>)}
                            </ul>
                        </div>
                        <label className={cn('input-label')} htmlFor="inputText">Персонифицируйте свой чек-лист</label>
                        {/*<input className={cn('input-field')} id="inputText" type="text"*/}
                        {/*       placeholder="Впишите имя и/или мотивирующую фразу" value={inputValue}*/}
                        {/*       onFocus={() => {*/}
                        {/*           setIsKeyboardOpened(true);*/}
                        {/*           setTypeKeyboard('input');*/}
                        {/*       }}*/}
                        {/*       onChange={(e) => setInputValue(e.target.value)}/>*/}
                        <div className={cn('input-field')} onClick={() => {
                            setIsKeyboardOpened(true);
                            setTypeKeyboard('input');
                        }}>
                            {inputValue ? inputValue : <span className={cn('input-span')}>Впишите имя и/или мотивирующую фразу</span>}
                        </div>
                    </div>
                    <div className={cn('right-block')}>
                        <div className={cn('img-wrapper')} id="pdf-content">
                            <img src={resultImg} alt=""/>
                            <div className={cn('img-container')}>
                                <h2 className={cn('img-title')}>{resultItem?.title}</h2>
                                <div className={cn('img-content')}>
                                    <div className={cn('img-description')}>{resultItem?.description}</div>
                                    <div className={cn('img-list')}>
                                        <p className={cn('img-list-title')}>{resultItem?.secondMessage?.messageTitle}</p>
                                        <ol className={cn('img-list-container')}>
                                            {resultItem?.secondMessage?.advices.map(i => <li>{i}</li>)}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className={cn('motivation')}>
                                {inputValue ? inputValue : "Впишите имя и/или мотивирующую фразу"}
                            </div>
                        </div>
                        <div className={cn('img-buttons')}>
                            <button className={cn('img-btn')} onClick={() => openModalWindow()}>
                                ОТПРАВИТЬ НА ПОЧТУ
                            </button>
                            <button className={cn('img-btn')} onClick={() => {
                                clearScore();
                                navigate('/finance-habits/1');
                            }}>ПРОЙТИ ЕЩЕ РАЗ</button>
                        </div>
                    </div>
                </div>
                {!isKeyboardOpened
                    ?
                    <ModalWindow windowState={isEmailOpened} setModal={setIsEmailOpened} cross={greenCross}
                                 stripColor="#014F9E">
                        <EmailModal setIsKeyboardOpened={setIsKeyboardOpened} emailValue={emailValue}
                                    setEmailValue={setEmailValue} setIsEmailOpened={setIsEmailOpened} data={generatedPDF}
                                    setTypeKeyboard={setTypeKeyboard}
                                    type="pdf"/>
                    </ModalWindow>
                    :
                    <ModalWindow windowState={isKeyboardOpened} setModal={setIsKeyboardOpened} cross={greenCross}
                                 stripColor="#014F9E">
                        <KeyBoard emailValue={emailValue} setEmailValue={setEmailValue} inputValue={inputValue}
                                  setInputValue={setInputValue}
                                  isEmailOpened={isEmailOpened} typeKeyboard={typeKeyboard}
                                  setIsKeyboardOpened={setIsKeyboardOpened}/>
                    </ModalWindow>
                }
            </div>
        </div>
    );
};

export default FinanceHabitsResult;