import React from 'react';
import classNames from 'classnames/bind';
import styles from './ModalWindow.module.scss';

const cn = classNames.bind(styles);

const ModalWindow = ({ children, windowState, setModal, cross, stripColor }) => {

	return (
		<div className={cn('window', {'active': windowState})} onClick={() => setModal(false)}>
			<div className={cn('window__content')} onClick={event => event.stopPropagation()}>
				<div className={cn('close-btn')}>
					<img src={cross} alt="" onClick={() => setModal(false)}/>
				</div>
				{children}
				<div className={cn('bottom-strip')} style={{background: stripColor}} />
			</div>
		</div>
	);
};

export default ModalWindow;