import React from 'react';
import classNames from 'classnames/bind';
import styles from './MainBlock.module.scss';
import {useNavigate} from 'react-router-dom';

const cn = classNames.bind(styles);

const MainBlock = ({color, text, btn, url}) => {
    const navigate = useNavigate();

    return (
        <div className={cn('wrapper')} style={{background: color}}>
            <div className={cn('text-wrapper')}>
                <p className={cn('text')}>{text}</p>
            </div>
            <button className={cn('button')} onClick={() => {
                navigate(url);
                if (url === 'tracker') window.location.reload();
            }}>{btn}</button>
        </div>
    );
};

export default MainBlock;