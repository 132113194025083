import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Region.module.scss';
import Header from "../../components/Header/Header";
import bestProjectsStrips from '../../assets/images/Header/best-projects-strips.png'
import axios from 'axios';

const cn = classNames.bind(styles);

const Region = () => {
	const [region, setRegion] = useState({})

	// const fetchRegion = async () => {
	// 	const address = 'https://app-dev.xn--80apaohbc3aw9e.xn--p1ai/api/module/map'
	// 	const colors = ['#43A99E', '#4FC4BB', '#80D4CF', '#B3E5E2', '#D9F2F0']

	// 	const { data: { data } } = await axios.get(address)

	// 	data
	// 		.sort(sortRegions('projects_count'))
	// 		.map((el, i) => el.color = (colors[Math.floor(i / 17)] ? colors[Math.floor(i / 17)] : '#43A99E'));
	// 		const collator = new Intl.Collator();
	// 		data.sort((a, b) => collator.compare(a.title, b.title));

	// 	setRegion(data)
	// }

	// useEffect(() => {
	// 	fetchRegion()
	// }, [])

	return (
		<>
			<div className={cn('wrapper')}>
				{/* <Header background="#014F9E" text="Карта регионов"
					strips={bestProjectsStrips} />
				<div className={cn("map-container")}>
					{
						regions.length
							? <MapComponent regions={regions} />
							: <div className={cn('loading')}>Loading...</div>
					}
				</div> */}
				REGION
			</div>
		</>
	);
};

export default Region;