export default class wheelOfLife {
	settings = { 
		areasGradShift: 90, // Поворот областей против часовой
		levels: 9, // Число уровней в секторе

		opacity: 0.8, // Прозрачность сектора
		
		// Радиус можно задать как в пикселях, так и в долях к минимальному размеру
		// radius: 150,
		radius: 0.8,

		fontSize: 14, // Размер шрифта подписей в пикселях
		maxFontSize: 18, // Максимальный размер вычисленного шрифта подписи
		fontFamily: 'Rubik',

		canvasName: 'canvas', // ID элемента канвы
		canvasColor: '#FFFFFF', // #EFEFEF
		strokeColor: '#FFFFFF', // #EFEFEF
		
		strokeWidth: 2,

		lineHeight: 1.2, // Межстрочный интервал (используется для отступов от круга)
		letterSpacing: 1, // Меюбуквенный интервал подписи
		textShadowColor: '', // #AAA Цвет тени (если не задавать - тени не будет)

		// Хитрые коэффициенты для размера подписи
		textHeightCoeff: 0.9,
		textWidthCoeff: 0.95,
		textOffsetCoeffCW: 1,
		textOffsetCoeffCCW: 1.7,

		levelTextColor: '#FFFA',
		levelFontSize: 14, // in pixels
	};

	currentItem;
	currentLevel;
	currentRadius;
	size;

	width;
	height;

	visibleHint;

	constructor(areas, settings, canvas) {
		if (!areas || !areas.length) {
			return false;
		}

		this.areas = areas;

		this.settings = settings && {...this.settings, ...settings} || this.settings

		this.canvas = canvas.current;
		if (!this.canvas) {
			return false;
		}


		this.destroyListeners()
		this.render();
		
		window.removeEventListener('resize', this.render.bind(this));
		window.addEventListener('resize', this.render.bind(this));

	};

	render() {
		this.bootstrap();		
		
		this.drawAll();
	}

	bootstrap() {
		this.canvas.width = this.canvas.clientWidth;
		this.canvas.height = this.canvas.clientWidth;

		this.width = this.canvas.width;
		this.height = this.canvas.height;
		this.size = Math.min(this.width, this.height);

		
		this.settings.radius =  this.size / 2.9;

		this.ctx = this.canvas.getContext('2d');
		this.center = {
			x: this.width / 2,
			y: this.height / 2
		}

		this.angleStep = 360 / this.areas.length;
		this.levelRadiusStep = this.settings.radius / this.settings.levels;
		this.hasCompleted = false;

		this.canvas.removeEventListener('mousemove', this.onMouseMove.bind(this));
		this.canvas.removeEventListener('click', this.onClick.bind(this));

		this.canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
		this.canvas.addEventListener('click', this.onClick.bind(this));
	}

	destroyListeners() {
		window.removeEventListener('resize', this.render.bind(this));
		this.canvas.removeEventListener('mousemove', this.onMouseMove.bind(this));
		this.canvas.removeEventListener('click', this.onClick.bind(this));
	}

	degreeToRad(degree) { // перевод градусов в радианы
		return degree * Math.PI / 180
	}

	getCoords(radius, angle) { // Вычисление координат от центра круга
		return {
			x: this.center.x + radius * Math.cos(this.degreeToRad(angle)),
			y: this.center.y + radius * Math.sin(this.degreeToRad(angle)),
		}
	};

	getRadiusAngle(offsetX, offsetY) { // Получение радиуса и угла для координат
		let dX = this.center.x - offsetX;
		let dY = this.center.y - offsetY;
		return {
			angle: 180 + Math.atan2(dY, dX) * (180 / Math.PI),
			radius: Math.sqrt(dX*dX + dY*dY)
		}
	};


	getLevel(radius) { // Получение уровня из радиуса
		return Math.ceil(radius / this.levelRadiusStep);
	};


	drawAll() { // Отрисовка всей канвы
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this.ctx.fillStyle = this.settings.canvasColor;
		this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

		// Рисуем секторы и подписи
		this.areas.forEach((item, idx) => {
			item.angle = idx * this.angleStep - this.settings.areasGradShift;
			item.angle = (item.angle < 0) ? (360 + item.angle) : ((item.angle > 360) ? (360 - item.angle) : (item.angle)); // Нормализуем угол

			this.drawSector(this.settings.radius, item.angle, item.color);
			let textItems = item.title.split('|');
			
			let textHeight = 0;
			let allTextHeight = 0;
			textItems.forEach((text, idx) => {
				textHeight = this.drawCircularText(text, this.settings.radius + textHeight * idx, item.angle, item.color, (item.angle >= 180));
				allTextHeight = textHeight * idx
			})

			item.textRadius = this.settings.radius + allTextHeight;

			//console.log(item.title, allTextHeight)


			
		});

		// Рисуем активый сектор
		if (this.currentItem && this.currentLevel) {
			this.drawSector(this.currentLevel * this.levelRadiusStep, this.currentItem.angle, this.currentItem.color, false);
		}

		// Рисуем секторы с заданными уровнями
		this.areas.filter(item => !!item.level).forEach(item => {
			if (item != this.currentItem) {
				this.drawSector(item.level * this.levelRadiusStep, item.angle, item.color, false);
			}

			this.drawLevel(item.level, item.angle);
		})

		

	}

	drawSector(radius, angle, color, isTransparent = true) { // Отрисовка сектора
		let coords = this.getCoords(radius, angle)
		
		this.ctx.save();

		this.ctx.beginPath();
		
		this.ctx.moveTo(this.center.x, this.center.y);
		this.ctx.lineTo(coords.x, coords.y)
		this.ctx.arc(this.center.x, this.center.y, radius, this.degreeToRad(angle), this.degreeToRad(angle + this.angleStep));	
		this.ctx.lineTo(this.center.x, this.center.y);
	
		this.ctx.fillStyle = color;
		this.ctx.globalAlpha = isTransparent ? this.settings.opacity : 1;
		this.ctx.fill();
	
		this.ctx.lineWidth = this.settings.strokeWidth;
		this.ctx.strokeStyle = this.settings.strokeColor;
		this.ctx.globalAlpha = 1;
		this.ctx.stroke();

		this.ctx.restore();
	};

	drawCircularText(str, radius, angle, color, clockwise = true) { // Отрисовка подписи

		let direction = clockwise ? 1 : -1;
		let textOffset = this.settings.fontSize;
	
	
		const angleRad = this.degreeToRad(direction * 90 +  angle);
		const angleStepRad = this.degreeToRad(this.angleStep);
		const sectorWidth = angleStepRad * radius * this.settings.textWidthCoeff;
	
		let textHeight = this.settings.fontSize * this.settings.lineHeight;
	
		this.ctx.save();

		this.ctx.fillStyle = color;
		this.ctx.font = this.settings.fontSize + 'px ' + this.settings.fontFamily;
	
		let strWidth = this.ctx.measureText(str).width * this.settings.letterSpacing;
		const strRatio = strWidth / textHeight;
	
	
		// Определяем размер шрифта в зависимости от соотношения ширины сектора и строки
		textHeight = (sectorWidth * this.settings.textHeightCoeff) / strRatio;
		if (textHeight > this.settings.maxFontSize * this.settings.lineHeight) { // Учитываем ограничение макс. размера
			textHeight = this.settings.maxFontSize * this.settings.lineHeight;
		}
	
		this.ctx.font = (textHeight / this.settings.lineHeight) + 'px ' + this.settings.fontFamily;
		strWidth = this.ctx.measureText(str).width * this.settings.letterSpacing;
	
		const strRad = strWidth / radius; // длина текста в радианах
		const offsetRad = (angleStepRad * this.settings.textWidthCoeff - strRad) / 2;
	
		this.ctx.translate(this.center.x, this.center.y);
		this.ctx.rotate(angleRad + (clockwise ? offsetRad : angleStepRad - offsetRad));
	
		for (let i = 0; i < str.length; i++) {
			let charWidth = this.ctx.measureText(str[i]).width * this.settings.letterSpacing;
			this.ctx.rotate(direction * ((charWidth / 2) / radius)); // поворачиваем канву на половину буквы
	
			
			if (this.settings.textShadowColor) { // Рисуем тень текста, если задан ее цвет
				this.ctx.save();
				this.ctx.fillStyle = this.settings.textShadowColor;
				this.ctx.fillText(str[i], 1, -direction * (radius + textOffset * (clockwise ? this.settings.textOffsetCoeffCW : this.settings.textOffsetCoeffCCW) / this.settings.lineHeight));
				this.ctx.restore();
			}
			
			
			this.ctx.fillText(str[i], 0, -1 * direction * (radius + textOffset * (clockwise ? this.settings.textOffsetCoeffCW : this.settings.textOffsetCoeffCCW) / this.settings.lineHeight));
			this.ctx.rotate(direction * ((charWidth / 2) / radius));
		}
	
		this.ctx.restore();

		return textHeight / this.settings.lineHeight;
	};

	drawLevel(level, angle) { // Отрисовываем цифру с уровнем для сектора
		let radius = level * this.levelRadiusStep - this.settings.levelFontSize * (level > 2 ? 1 : -1);
		let coords = this.getCoords(radius, angle + (this.angleStep / 2));
		
		this.ctx.save();
		this.ctx.fillStyle = this.settings.levelTextColor;
		this.ctx.font = this.settings.levelFontSize + 'px ' + this.settings.fontFamily;
		this.ctx.fillText(level, coords.x - 5, coords.y + 5);
		this.ctx.restore();
	}

	showHint(item) {
		if (!this.visibleHint) {
			this.visibleHint = document.querySelector('.wheel .hint');
		}

		this.visibleHint.style.opacity = '1';
		this.visibleHint.innerText = item.hint;
	}

	hideHint() {
		this.visibleHint.style.opacity = '0'
	}

	onMouseMove(e) { // Обработчик ховера над сектором
		let radiusAngle = this.getRadiusAngle(e.offsetX, e.offsetY)
		let redraw = false;

		if (radiusAngle.radius <= this.settings.radius) {
			let currentItem = this.areas.find(el => { // ищем сектор для полученного угла
				if (radiusAngle.angle > 0 && radiusAngle.angle < 30) return el.angle === 330;
				return (el.angle <= radiusAngle.angle) && (el.angle + this.angleStep >= radiusAngle.angle)
			});

			if (currentItem && currentItem != this.currentItem) {
				this.currentItem = currentItem;
				redraw = true;
				this.canvas.style.cursor = 'pointer'
				this.showHint(currentItem)
			}

			let currentLevel = this.getLevel(radiusAngle.radius)
			if (currentLevel != this.currentLevel) {
				this.currentLevel = currentLevel;
				redraw = true;

				if (!this.canvas.style.cursor) {
					this.canvas.style.cursor = 'pointer';
				}

				
			}

			

		} else if (this.currentItem) {
			this.currentItem = null;
			redraw = true;
			this.canvas.style.cursor = ''
			this.hideHint();
		}

		redraw && this.drawAll(); // Перерисовываем при каком-либо изменении
	};

	onClick() { // Обработчик клика по сектору
		if (!this.hasCompleted && this.currentItem && this.currentLevel) {
			this.currentItem.level = this.currentLevel;
		}

		this.drawAll();

		if (!this.hasCompleted && !this.areas.filter(item => !item.level).length) { // Обрабатываем все заполненные уровнем секторы
			this.hasCompleted = true; // чтоб два раза не повторяться
			this.completed();
			
		}

		
	};

	completed() { // Функционал при всех заполненных секторах
		// alert('Completed!' + "\n" + this.areas.map(item => item.title + ': ' + item.level));
		if (typeof(this.settings.onCompleted) === 'function') {
			this.settings.onCompleted(this.areas.map(item => item.level))
		}
	};

	

}




