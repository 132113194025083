import React from 'react';
import classNames from 'classnames/bind';
import styles from './BestProjectsModal.module.scss';
import leftArrow from '../../assets/images/BestProjects/left-arrow.svg';
import rightArrow from '../../assets/images/BestProjects/right-arrow.svg';

const cn = classNames.bind(styles);

const BestProjectsModal = ({id, title, place, description, amount, images, openModal}) => {

    return (
        <div className={cn('modal-wrapper')}>
            <div className={cn('content')}>
                <h2 className={cn('content__title')}>{title}</h2>
                <p className={cn('content__place')}>{place}</p>
                <p className={cn('content__description')}>{description}</p>
                <p className={cn('content__amount')}>Благополучатели: <b>{amount}</b></p>
            </div>
            <div className={cn('images')}>
                <img src={images.img1} alt=""/>
                <img src={images.img2} alt=""/>
                <img src={images.img3} alt=""/>
            </div>
            <div className={cn('buttons')}>
                <button className={cn('btn')} onClick={() => openModal(id - 1)}>
                    <img src={leftArrow} alt=""/>
                    <span>Предыдущий проект</span>
                </button>
                <button className={cn('btn')} onClick={() => openModal(id + 1)}>
                    <span>Следущий проект</span>
                    <img src={rightArrow} alt=""/>
                </button>
            </div>
        </div>
    );
};

export default BestProjectsModal;