import React, {useState} from 'react';
import {createContext} from "react";

export const TrackerWheelContext = createContext();

const TrackerContext = ({children}) => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [resultQuality, setResultQuality] = useState(0);
    const [highResults, setHighResults] = useState([]);
    const [lowResults, setLowResults] = useState([]);

    const calculateAnswer = (result) => {
        const isHighResult = result.every(item => item >= 9);

        result.forEach((item, index) => {
            if (item >= 6) {
                setHighResults(prev => [...prev, index])
            } else {
                setLowResults(prev => [...prev, index])
            }
        });

        if (isHighResult) {
            setResultQuality(2);
        } else if (highResults.length && !lowResults.length) {
            setResultQuality(1);
        } else {
            setResultQuality(0);
        }
    }

    return (
        <>
            <TrackerWheelContext.Provider
                value={{
                    calculateAnswer,
                    isCompleted,
                    setIsCompleted,
                    resultQuality,
                    setResultQuality,
                    highResults,
                    setHighResults,
                    lowResults,
                    setLowResults
                }}>
                {children}
            </TrackerWheelContext.Provider>
        </>
    );
};

export default TrackerContext;