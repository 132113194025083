import React, {useEffect, useState} from 'react';
import classNames from 'classnames/bind';
import styles from './BestPracticesPage.module.scss';
import Header from "../../components/Header/Header";
import bestPracticesStrips from "../../assets/images/Header/practicesStrips.svg";
import {bestPractices} from "../../data/bestPractices";
import {NavLink, useMatch} from "react-router-dom";
import arrow from '../../assets/images/Header/arrow.svg'
import leftArrow from '../../assets/images/BestPractices/left-arrow.svg';
import rightArrow from '../../assets/images/BestPractices/right-arrow.svg';

const cn = classNames.bind(styles);

const BestPracticesPage = () => {
    const [data, setData] = useState(null);
    const {params} = useMatch('best-practices/:pageNumber');

    useEffect(() => {
        const currentPage = bestPractices.filter(i => i.id === +params?.pageNumber);
        setData(currentPage[0]);
    }, [params]);

    return (
        <div className={cn('wrapper')}>
            <Header background="#DB8D8D" text="Лучшие региональные практики по финансовой грамотности – 2022"
                    strips={bestPracticesStrips}/>
            <div className={cn('content')}>
                <NavLink className={cn('return-btn')} to="/best-practices">
                    <img src={arrow} alt="" />
                    <span>Назад</span>
                </NavLink>
                <h2 className={cn('title')}>{data?.title}</h2>
                <div className={cn('container')}>
                    {data?.items.map(i =>
                        <div className={cn('item')}>
                            <div className={cn('image-content')}>
                                <div className={cn('image-inner')}>
                                    <img src={i?.image} alt=""/>
                                </div>
                                <p className={cn('field')}>{i?.field}</p>
                            </div>
                            <div className={cn('text-content')}>
                                <h3 className={cn('item-title')}>{i?.title}</h3>
                                <div className={cn('item-text')}>
                                    <p className={cn('item-description')}>{i?.text}</p>
                                    <div className={cn('item-point')}>Целевая аудитория: <span>{i?.audience}</span>
                                    </div>
                                    <div className={cn('item-point')}>Охват: <span>{i?.coverage}</span></div>
                                    <div className={cn('item-point')}>Разработчик: <span>{i?.developer}</span></div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cn('buttons')}>
                    <NavLink className={cn('btn')}
                             to={`/best-practices/${+params.pageNumber > 1 ? +params.pageNumber - 1 : 6}`}>
                        <img src={leftArrow} alt=""/>
                        Предыдущие практики
                    </NavLink>
                    <NavLink className={cn('btn')}
                             to={`/best-practices/${+params.pageNumber < 6 ? +params.pageNumber + 1 : 1}`}>
                        Следующие практики
                        <img src={rightArrow} alt=""/>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default BestPracticesPage;