import React, {useContext} from 'react';
import classNames from 'classnames/bind';
import styles from './TrackerResult.module.scss';
import decorFooter from '../../assets/images/Tracker/decor-footer.svg';
import logoFinance from '../../assets/images/Tracker/logo-finance.png';
import backArrowRed from '../../assets/images/Tracker/back-arrow-red.svg';
import wheel from '../../assets/images/Tracker/left-wheel.png';
import like from '../../assets/images/Tracker/like-icon.svg';
import warning from '../../assets/images/Tracker/warning-icon.svg';
import {TrackerWheelContext} from "../../context/trackerContext";
import Answers from "./Answers";
import {NavLink} from "react-router-dom";

const cn = classNames.bind(styles);

const TrackerResult = () => {

    const {
        isCompleted,
        highResults,
        lowResults,
        resultQuality
    } = useContext(TrackerWheelContext)

    return (
        <div className={cn('tracker')}>
            <img style={{opacity: 0}} className={cn('decor-footer')} src={decorFooter} alt=""/>
            <div className={cn('tracker__logo')}>
                <img src={logoFinance} alt=""/>
            </div>

            <NavLink to="/tracker/wheel" className={cn("back", 'tracker__back')}>
                <img src={backArrowRed} alt=""/>
                <span>Назад</span>
            </NavLink>

            <div className={cn('tracker__info')}>
                <h1 className={cn('tracker__info-title')}>Трекер ФинЗОЖ</h1>
                <img src={wheel} alt=""/>
            </div>
            <div className={cn('tracker__body')}>
                <div className={cn('tracker__left')}>
                    <div className={cn('tracker__title')}>
                        <img src={like} alt=""/>
                        <p className={cn('tracker__title-text')}>Отличное состояние:</p>
                        <Answers isCompleted={isCompleted} highResults={highResults}
                                 resultQuality={resultQuality}/>
                    </div>
                </div>
                <div className={cn('tracker__center')}></div>
                <div className={cn('tracker__right')}>
                    <p className={cn('tracker__title-top-text')}>Мои финансы в балансе, когда развитие идет
                        равномерно. Поэтому обратите внимание на слабые сферы.</p>
                    <div className={cn('tracker__title')}>
                        <img src={warning} alt=""/>
                        <p className={cn('tracker__title-text')}>Сферы, требующие внимания:</p>
                        <Answers isCompleted={isCompleted} lowResults={lowResults}
                                 resultQuality={resultQuality}/>
                    </div>
                </div>
                <NavLink className={cn('tracker__btn')} to="/tracker/advices-1">ДАЛЕЕ</NavLink>
            </div>

        </div>
    );
};

export default TrackerResult;