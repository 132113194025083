import React from 'react';
import './Tracker.scss';
import decorFooter from '../../assets/images/Tracker/decor-footer.svg';
import logoFinance from '../../assets/images/Tracker/logo-finance.png';
import mainPreview from '../../assets/images/Tracker/main-preview.svg';
import {NavLink} from "react-router-dom";
import backArrow from "../../assets/images/Tracker/back-arrow.svg";


const Tracker = () => {
    return (
        <div className="decor-wrapper">
            <img className="decor-footer" src={decorFooter} alt=""/>
            <div className="more">
                <div className="more__logo">
                    <img src={logoFinance} alt=""/>
                </div>
                <NavLink to="/" className="back tracker__back">
                    <img src={backArrow} alt=""/>
                    <span>Меню</span>
                </NavLink>
                <div className="more__info">
                    <div className="more__tracker">
                        <h1 className="more__tracker-title">Трекер ФинЗОЖ</h1>
                        <p className="more__tracker-text">поможет оценить ваш уровень финансового здоровья</p>
                    </div>
                    <div className="more__view">
                        <div className="more__view-text">Хотите узнать больше?</div>
                        <NavLink to="/tracker/wheel" className="more__view-btn">ДА</NavLink>
                    </div>
                </div>
                <div className="more__preview">
                    <img src={mainPreview} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Tracker;