import React from 'react';
import classNames from 'classnames/bind';
import styles from './BestPractices.module.scss';
import Header from "../../components/Header/Header";
import bestPracticesStrips from '../../assets/images/Header/practicesStrips.svg';
import QR from '../../assets/images/BestPractices/qr.svg';
import BestPracticesBlock from "../../components/BestPracticesBlock/BestPracticesBlock";

const cn = classNames.bind(styles);

const BestPractices = () => {

    return (
        <div className={cn('wrapper')}>
            <Header background="#DB8D8D" text="Лучшие региональные практики по финансовой грамотности – 2022"
                    strips={bestPracticesStrips}/>
            <div className={cn('content')}>
                <div className={cn('grid-container')}>
                    <div className={cn('left')}>
                        <BestPracticesBlock side="left" position="top" link="1"
                                            text="Спецпроекты со СМИ, блогерами и в соцсетях"/>
                        <BestPracticesBlock side="left" position="center" link="2"
                                            text="Спецпроекты с органами соцзащиты, МФЦ, ПФР, ФНС и др."/>
                        <BestPracticesBlock side="left" position="bottom" link="3"
                                            text="Спецпроекты с общественными организациями и движениями"/>
                    </div>
                    <div className={cn('center')}>
                        <div className={cn('circle', 'circle-big')}>
                            <div className={cn('circle', 'circle-middle')}>
                                <div className={cn('circle', 'circle-small')}>
                                    <img src={QR} alt=""/>
                                    <p className={cn('circle-text')}>
                                        Еще больше региональных практик на портале Моифинансы.рф
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cn('right')}>
                        <BestPracticesBlock side="right" position="top" link="4" text="Спецпроекты для людей с ОВЗ"/>
                        <BestPracticesBlock side="right" position="center" link="5"
                                            text="Спецпроекты для людей старшего возраста"/>
                        <BestPracticesBlock side="right" position="bottom" link="6" text="Цифровые продукты"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestPractices;