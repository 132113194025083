import React from 'react';
import classNames from 'classnames/bind';
import styles from './BestProjectsCard.module.scss';

const cn = classNames.bind(styles);

const BestProjectsCard = ({id, pic, name, location, openModal}) => {
    return (
        <div className={cn('wrapper')} onClick={() => openModal(id)}>
            <img src={pic} alt="img"/>
            <p className={cn('name')}>{name}</p>
            <p className={cn('location')}>{location}</p>
        </div>
    );
};

export default BestProjectsCard;