import React, {useEffect, useState, useContext} from 'react';
import classNames from 'classnames/bind';
import styles from './PushkinQuestion.module.scss';
import {NavLink, useMatch} from 'react-router-dom';
import Header from "../../components/Header/Header";
import pushkinStrips from '../../assets/images/Header/pushkin-strips.svg';
import {pushkinQuestions} from "../../data/pushkin";
import PushkinAnswerItem from "../../components/PushkinAnswerItem/PushkinAnswerItem";
import {PushkinContext} from "../../context/pushkinTestContext";

const cn = classNames.bind(styles);

const PushkinQuestion = () => {
    const [question, setQuestion] = useState({});
    const [checkedAnswer, setCheckedAnswer] = useState(null);
    const {params} = useMatch('pushkin/:questionNumber');
    const {trueAnswer, falseAnswer} = useContext(PushkinContext);

    useEffect(() => {
        const currentQuestion = pushkinQuestions.filter(i => i.id === +params?.questionNumber);
        setQuestion(currentQuestion[0]);
        setCheckedAnswer(null);
    }, [params]);

    return (
        <div className={cn('wrapper')}>
            <Header background="#E6215A" text={"Квиз: Стихи, долги и карты.\n Что вы знаете лучше Пушкина?"}
                    strips={pushkinStrips}/>
            <div className={cn('content')}>
                <div className={cn('left-block')}>
                    <p className={cn('number-string')}>Вопрос {question.id} из 6</p>
                    <p className={cn('question')}>{question?.question}</p>
                    <div className={cn('answers-block')}>
                        {question.answers?.map(i => <PushkinAnswerItem key={i.id} id={i.id} text={i.text}
                                                                       comment={i.comment} isRight={i.isRight}
                                                                       checkedAnswer={checkedAnswer}
                                                                       setCheckedAnswer={setCheckedAnswer}
                                                                       trueAnswer={trueAnswer}
                                                                       falseAnswer={falseAnswer}/>)}
                    </div>
                </div>

                <div className={cn('right-block')}>
                    <img className={cn('pushkin-img')} src={question.image} alt="pushkin"/>
                    <NavLink
                        to={`/pushkin/${+params.questionNumber <= 5 ? +params.questionNumber + 1 : 'result'}`}
                        className={cn('btn')}
                    >
                        Следующий вопрос
                    </NavLink>
                </div>
            </div>
        </div>

    );
};

export default PushkinQuestion;