import pushkin1 from '../assets/images/Pushkin/pushkin-1.png';
import pushkin2 from '../assets/images/Pushkin/pushkin-2.png';
import pushkin3 from '../assets/images/Pushkin/pushkin-3.png';
import pushkin4 from '../assets/images/Pushkin/pushkin-4.png';
import pushkin5 from '../assets/images/Pushkin/pushkin-5.png';
import pushkin6 from '../assets/images/Pushkin/pushkin-6.png';

export const pushkinQuestions = [
    {
        id: 1,
        question: 'В России и за рубежом есть множество памятников Пушкину. Русского поэта любят во всем мире. Но самый первый памятник поэт воздвиг себе сам. Как же ему это удалось?',
        answers: [
            {
                id: 1,
                text: 'С помощью инициативного бюджетирования. Пушкин объединил домовладельцев и обратился в Думу с просьбой выделить средства на устройство Литературного сквера, где будут памятники поэтам, в том числе и ему.',
                isRight: false,
                comment: 'Извините, но мы выдумали эту историю, в Петербурге даже нет Литературного сквера. А первый памятник Пушкину появился в Москве в 1880 году. ',
            },
            {
                id: 2,
                text: 'С помощью краудфандинга. В журнале «Современник» Пушкин объявил об открытии «сбора средств на нужды улучшения града Петра и открытия новых памятников: Державину, Баратынскому и Пушкину».',
                isRight: false,
                comment: 'Пушкин и правда был издателем и главным редактором «Современника», но он никогда не использовал его в таких целях. Первый памятник Пушкину появился в Москве в 1880 году, вы его наверняка знаете, он стоит на Тверской.',
            },
            {
                id: 3,
                text: 'Стихи Пушкина были нематериальными активами того времени. Его произведения стали основой русской литературы.',
                isRight: true,
                comment: 'Да! Еще при жизни талант Пушкина был признан не только критиками и издателями. Простые читатели голосовали рублем и раскупали его книги. Так что, когда Пушкин писал «я памятник себе воздвиг нерукотворный», – он не переоценивал себя. ',
            },
        ],
        image: pushkin1,
    },
    {
        id: 2,
        question: 'Все годы семейной жизни в Петербурге Александр Сергеевич и Наталья Николаевна арендовали жилье, сменив 7 квартир за 6 лет. Свою они так и не смогли купить. А что бы им помогло приобрести квартиру сегодня? ',
        answers: [
            {
                id: 1,
                text: 'Они могли бы взять военную ипотеку.',
                isRight: false,
                comment: 'Увы, это распространенное заблуждение, что Пушкин был военным. Он был чиновником, камер-юнкером при дворе – это никак не связанное с армией звание. ',
            },
            {
                id: 2,
                text: 'Они бы могли поучаствовать в федеральной программе «Молодая семья».',
                isRight: true,
                comment: 'Точно! Пушкину на момент венчания было 32 года, а Наталье Николаевне – 19 лет, и они подошли бы для участия в программе «Молодая семья», ведь там возраст каждого из супругов должен быть до 35 лет. Кстати, для того времени назвать Пушкина  молодым мужем было бы затруднительно. ',
            },
            {
                id: 3,
                text: 'Сегодня они бы продали часть родительских имений и купили бы себе квартиру.',
                isRight: false,
                comment: 'Кажется вполне рабочим вариантом, но он исторически неточен. У Александра Сергеевича и Натальи Николаевны вся недвижимость в виде родовых имений была или заложена, или имела обременение несовершеннолетними детьми, которые были там «прописаны».  ',
            },
        ],
        image: pushkin2,
    },
    {
        id: 3,
        question: 'Родители Натальи Гончаровой не приветствовали ее брак с Пушкиным и не торопились давать свое согласие. Пушкин был небогат, Гончаровы хотели подождать более выгодной партии. Чтобы добиться согласия на брак, влюбленный Пушкин пообещал ее матери… ',
        answers: [
            {
                id: 1,
                text: ' …украсть невесту и сбежать с ней на Кавказ. Мать, напуганная угрозами безумца, сразу согласилась выдать за него дочь.',
                isRight: false,
                comment: 'Романтично и в духе Пушкина, но нет. Такой скандалЪ был бы вреден репутации Пушкина, а кроме того нанес бы огромный финансовый ущерб. ',
            },
            {
                id: 2,
                text: '…содержать имение Гончаровых в течение 10 последующих лет, оплачивая по нему все счета.',
                isRight: false,
                comment: 'Предложение выгодное, особенно учитывая, что Гончаровы имели массу долгов и имение было проблемным активом. Но и у самого Пушкина с финансами было не очень, так что такого он предложить не мог.  ',
            },
            {
                id: 3,
                text: '…инвестировать свои деньги в приданое Натальи Гончаровой.',
                isRight: true,
                comment: 'Предложение выгодное, особенно учитывая, что Гончаровы имели массу долгов и имение было проблемным активом. Но и у самого Пушкина с финансами было не очень, так что такого он предложить не мог.  ',
            },
        ],
        image: pushkin3,
    },
    {
        id: 4,
        question: 'Семья Пушкиных была небогата, однако она сделала все возможное, чтобы Александр смог учиться в новом престижном Императорском Царскосельском лицее. Как членам семьи это удалось? ',
        answers: [
            {
                id: 1,
                text: 'Они продали одно из имений, чтобы оплатить учебу сыну.',
                isRight: false,
                comment: 'Нет, этого не пришлось делать. Обучение в Лицее было бесплатным, надо было только сдать экзамены. ',
            },
            {
                id: 2,
                text: 'Они взяли образовательный кредит только для обучения мальчиков в Императорской казне.',
                isRight: false,
                comment: 'Нет, такого кредита не было, хоть и правда, что обучались в Лицее только мальчики. Зато сейчас каждый может взять образовательный кредит с господдержкой. Вне зависимости от гендерной принадлежности.',
            },
            {
                id: 3,
                text: 'Дядя Пушкина – известный поэт – договорился, что маленького Сашу посмотрит приемная комиссия. А обучение было бесплатным.',
                isRight: true,
                comment: 'Точно! Надо было только сдать вступительные экзамены. Все обучение оплачивало государство, поскольку в Лицее планировали воспитать будущих чиновников высокого ранга.',
            },
        ],
        image: pushkin4,
    },
    {
        id: 5,
        question: 'Пушкины регулярно посещали балы и вели образ жизни состоятельных людей. Однако после смерти поэта оказалось, что вся роскошная жизнь была не по средствам: семья была не в состоянии выплатить огромные долги. Почему так получилось?',
        answers: [
            {
                id: 1,
                text: 'Пушкин инвестировал все деньги в поместье, пытаясь реализовать там новую управленческую и экономическую модель.',
                isRight: false,
                comment: 'Пушкин следил за экономическими новостями и считал, что России нужна реформа, но все же не настолько. Его долги по большей части были из-за того, что он проигрывал огромные суммы в карты.',
            },
            {
                id: 2,
                text: 'Пушкину очень не везло в карты, он мог проиграть за ночь сумму, равную годовому жалованию.',
                isRight: true,
                comment: 'К сожалению, это правда. Александр Сергеевич был игроманом. Иногда он делал над собой усилие и переставал играть, но потом вновь возвращался к карточному столу.',
            },
            {
                id: 3,
                text: 'Бюджет семьи вела Наталья Николаевна, а Пушкин даже не знал, что у них долги.',
                isRight: false,
                comment: 'Вы же понимаете, что это шутка:)  Наталья Николаевна про часть долгов знала, но повлиять на мужа не могла.  ',
            },
        ],
        image: pushkin5,
    },
    {
        id: 6,
        question: 'Однажды даже министр финансов Егор Францевич Канкрин помог Пушкину. Он обратился к Государю и попросил для поэта льготные условия ссуды на издание «Истории Пугачевского бунта», договорившись, что…',
        answers: [
            {
                id: 1,
                text: '… следующий роман Пушкина будет о русских финансистах.',
                isRight: false,
                comment: 'Очень хорошая PR-идея, но ответ неправильный.',
            },
            {
                id: 2,
                text: '…Пушкин получит ссуду без процентов и без вычетов в пользу увечных.',
                isRight: true,
                comment: 'Да, именно так. Вычет в пользу увечных – это обязательные 5% в пользу воинов, пострадавших в Отечественной войне 1812 г. Пушкин их не платил, как и проценты по кредиту.',
            },
            {
                id: 3,
                text: '…после издания этой книги Пушкин снова уедет в Михайловское и не будет появляться при дворе.',
                isRight: false,
                comment: 'Нет, такое министр финансов предложить просто не мог. Это не соответствовало его должностным обязанностям. ',
            },
        ],
        image: pushkin6,
    },
]

export const pushkinResults = [
    {
        title: 'Золотая медаль',
        boldText: 'Поздравляем, сам Пушкин пожал бы вам руку! Вы бы с легкостью могли помочь ему решить финансовые проблемы – вы отлично ориентируетесь в финансовой культуре прошлого и настоящего.',
        text: 'И если вы где-то ошибаетесь или чего-то не знаете, то это несложно поправить: рекомендуем посмотреть видеоподкаст «Пушкин знает!», который можно найти в группе «Мои финансы» ВКонтакте. Переходите по QR-коду и сохраняйте в закладки!',
    },
    {
        title: 'Серебряная медаль',
        boldText: 'Мы рады, что вы, как и мы, любите нашего великого русского поэта!',
        text: 'И если вы где-то ошибаетесь или чего-то не знаете, то это несложно поправить: рекомендуем посмотреть видеоподкаст «Пушкин знает!», который можно найти в группе «Мои финансы» ВКонтакте. Переходите по QR-коду и сохраняйте в закладки!',
    },
    {
        title: 'Бронзовая медаль',
        boldText: 'Не критично, но все же мы бы советовали подтянуть знания, особенно по финансовой культуре.',
        text: 'И если вы где-то ошибаетесь или чего-то не знаете, то это несложно поправить: рекомендуем посмотреть видеоподкаст «Пушкин знает!», который можно найти в группе «Мои финансы» ВКонтакте. Переходите по QR-коду и сохраняйте в закладки!',
    },
]
