import React from 'react';
import classNames from 'classnames/bind';
import styles from './FinanceHabits.module.scss';
import Header from "../../components/Header/Header";
import financeHabitsStrips from '../../assets/images/Header/finance-habits-strips.svg';
import {NavLink} from "react-router-dom";
import mainImg from '../../assets/images/FinanceHabits/finance-habits-main.png';

const cn = classNames.bind(styles);

const FinanceHabits = () => {

    return (
        <div className={cn('wrapper')}>
            <Header background="#00A555" text={"Трекер вредных \n финансовых привычек"}
                    strips={financeHabitsStrips}/>
            <div className={cn('content')}>
                <div className={cn('flex-container')}>
                    <div className={cn('left-block')}>
                        <h2 className={cn('title')}>Диагностируйте у себя привычки, которые негативно влияют на личный и семейный бюджет. А затем начните борьбу с ними с помощью наших рекомендаций.</h2>
                        <p className={cn('text')}>Выберите пункты, которые вас характеризуют.
                            В каждой категории ответов может быть несколько или ни одного.
                        </p>
                        <p className={cn('warning')}>Только отвечайте честно!</p>
                        <NavLink className={cn('btn')} to="/finance-habits/1">НАЧАТЬ</NavLink>
                    </div>
                    <div className={cn('right-block')}>
                        <img src={mainImg} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinanceHabits;