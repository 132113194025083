export const cardsData = [
    {
        color: '#E6215A',
        url: 'pushkin',
        text: 'Квиз:\n Стихи, долги и карты.\n Что вы знаете лучше Пушкина?',
        btn: 'Пройти тест'
    },
    {color: '#00A555', url: 'finance-habits', text: 'Трекер вредных финансовых привычек', btn: 'УЗНАТЬ БОЛЬШЕ'},
    {color: '#EF7D00', url: 'finance-quizzes', text: 'Финансовые \n загадки', btn: 'Проверить себя'},
    {color: '#31B7BC', url: 'tracker', text: 'Трекер финансового здоровья', btn: 'УЗНАТЬ БОЛЬШЕ'},
    {color: '#DB8D8D', url: 'best-practices', text: 'Лучшие региональные практики по финансовой грамотности – 2022', btn: 'УЗНАТЬ БОЛЬШЕ'},
    {
        color: '#014F9E',
        url: 'best-projects',
        text: 'Инициативное бюджетирование – 2022. Лучшие проекты',
        btn: 'УЗНАТЬ БОЛЬШЕ'
    },
	//  {color: '#DB8D8D', url: 'regions', text: 'Карта регионов', btn: 'ПЕРЕЙТИ'},
]
