import img1 from '../assets/images/FinanceHabits/1.png';
import img2 from '../assets/images/FinanceHabits/2.png';
import img3 from '../assets/images/FinanceHabits/3.png';
import img4 from '../assets/images/FinanceHabits/4.png';
import img5 from '../assets/images/FinanceHabits/5.png';
import img6 from '../assets/images/FinanceHabits/6.png';

export const financeHabitsData = [
    {
        id: 1,
        title: 'ЕДА',
        answers: [
            {
                points: 1,
                text: 'каждый день покупаю кофе на вынос'
            },
            {
                points: 1,
                text: 'не составляю список покупок для магазина продуктов'
            },
            {
                points: 2,
                text: 'беру готовую еду на доставке'
            },
            {
                points: 1,
                text: 'покупаю дорогие экзотические продукты'
            },
            {
                points: 0,
                text: 'нет таких привычек'
            },
        ],
        image: img1,
    },
    {
        id: 2,
        title: 'ОДЕЖДА',
        answers: [
            {
                points: 2,
                text: '6 пара одинаковых туфель — это про меня',
            },
            {
                points: 2,
                text: 'покупаю одежду, чтобы поднять себе настроение',
            },
            {
                points: 1,
                text: 'покупаю вещи только из новых коллекций',
            },
            {
                points: 1,
                text: 'иду в магазин без понимания, что мне нужно',
            },
            {
                points: 1,
                text: 'покупаю статусную одежду',
            },
            {
                points: 0,
                text: 'нет таких привычек',
            },
        ],
        image: img2,
    },
    {
        id: 3,
        title: 'ТРАНСПОРТ',
        answers: [
            {
                points: 2,
                text: 'езжу на личном автомобиле каждый день',
            },
            {
                points: 1,
                text: 'езжу на личном автомобиле только по выходным или в случае необходимости',
            },
            {
                points: 2,
                text: 'езжу на такси каждый день даже в час-пик и там, где есть общественный транспорт',
            },
            {
                points: 0,
                text: 'нет таких привычек',
            },
        ],
        image: img3,
    },
    {
        id: 4,
        title: 'БЫТ',
        answers: [
            {
                points: 2,
                text: 'часто покупаю утварь для дома, кажется, мне нужен шкаф для посуды побольше',
            },
            {
                points: 2,
                text: 'не чиню технику, если она сломалась, а покупаю новую',
            },
            {
                points: 1,
                text: 'у меня есть фритюрница, пользовались один раз',
            },
            {
                points: 0,
                text: 'нет таких привычек',
            },
        ],
        image: img4,
    },
    {
        id: 5,
        title: 'САМОРАЗВИТИЕ И ДОСУГ',
        answers: [
            {
                points: 2,
                text: 'в прошлом году взял три курса, прошел только один',
            },
            {
                points: 2,
                text: 'купил годовой абонемент в спортзал, сходил пару раз',
            },
            {
                points: 1,
                text: 'хожу в кино каждую неделю',
            },
            {
                points: 1,
                text: 'покупаю книги и не читаю их',
            },
            {
                points: 0,
                text: 'нет таких привычек',
            },
        ],
        image: img5,
    },
    {
        id: 6,
        title: 'ЛИЧНЫЕ ФИНАНСЫ',
        answers: [
            {
                points: 2,
                text: 'пользуюсь кредиткой без необходимости',
            },
            {
                points: 2,
                text: 'не веду учет доходов и расходов',
            },
            {
                points: 1,
                text: 'делаю крупные покупки в первый день после зарплаты',
            },
            {
                points: 0,
                text: 'нет таких привычек',
            },
        ],
        image: img6,
    },
]

export const financeHabitsResult = [
    {
        title: 'Готовый финЗОЖник',
        description: 'Ого, здорово! Кажется, у вас все в порядке.',
        firstMessage: {
            messageTitle: 'Однако все равно дадим вам несколько универсальных советов:',
            advices: [
                'Старайтесь не совершать крупные покупки в день зарплаты, избегайте походов в торговый центр. Если что-то очень понравилось, вернитесь за этим завтра. Это поможет обдумать покупку.',
                'И не забывайте про списки покупок.',
                'В случае с одеждой это может быть подборка фотографий из Сети. Кстати, не забудьте перед походом в магазин заглянуть в шкаф. Может быть, что-то подобное там уже есть.'
            ]
        },
        secondMessage: {
            messageTitle: 'Над чем еще можно поработать:',
            advices: [
                'Не совершать крупные покупки в день зарплаты.',
                'Составлять списки покупок перед походом в магазин.',
                'Избегать бесцельных походов в ТЦ.',
                '...'
            ]
        }
    },
    {
        title: 'На пути к финЗОЖ',
        description: 'Неплохо, но есть над чем поработать.',
        firstMessage: {
            messageTitle: 'Дадим вам несколько универсальных советов:',
            advices: [
                'Старайтесь не совершать крупные покупки в день зарплаты, избегайте походов в торговый центр. Если что-то очень понравилось, вернитесь за этим завтра. Это поможет обдумать покупку.',
                'В течение года ведите учет расходов. Проводите ежеквартальную оптимизацию. Увеличивайте сумму, которая отправляется в несгораемый запас.',
                'Используйте шанс починить вещь, прежде чем покупать новую. Наверняка за углом есть мастерская, ателье или ремонт электроники. Это еще и экологично!'
            ]
        },
        secondMessage: {
            messageTitle: '',
            advices: [
                'Не совершать крупные покупки в день зарплаты.',
                'Вести учет доходов и расходов.',
                'Починить сломанные вещи, подогнать в ателье неподходящую одежду.',
                '...'
            ]
        }
    },
    {
        title: 'Зависимый от покупок',
        description: 'Опасненько! Тратить деньги и не следить за расходами приятно, но не полезно:(',
        firstMessage: {
            messageTitle: 'Пора задуматься о будущем: есть ли у вас финансовая подушка безопасности? Как вы будете жить в случае непредвиденного снижения дохода? Раз уж вы здесь, дадим вам несколько полезных советов:',
            advices: [
                'Начните с анализа доходов и расходов, самое простое — отследить их в банковском приложении. Посмотрите на динамику, на дни транжирства (может быть, это выходные или день прихода зарплаты). В такие дни можно прятать деньги от самого себя, например, снять с карты и положить в конверт. Если что-то очень хочется купить, самое простое — прийти за этим на следующий день.',
                'Никто не хочет лишаться всего и сразу, попробуйте скорректировать ваши вредные финансовые привычки. Например, покупайте кофе на вынос не каждый день, а через день. Увидите, вам понравится это маленькое достижение и захочется большего!'
            ]
        },
        secondMessage: {
            messageTitle: 'Цели на пути к финЗОЖ:',
            advices: [
                'Совершать покупки только на следующий день после возникновения потребности.',
                'Анализировать расходы в банковском приложении.',
                'Сократить число походов в магазин в течение месяца.',
                '...'
            ]
        }
    },
    {
        title: 'Безнадежный транжира',
        description: 'Хотя почему же безнадежный?  Вы уже прошли этот тест.',
        firstMessage: {
            messageTitle: '',
            advices: [
                'Попробуйте начать откладывать деньги, они, как известно, никогда лишними не бывают.',
                'Для этого используйте метод «хвостиков». В конце дня проверьте, сколько денег на банковской карте или в кошельке. Например, у вас осталось 1273 ₽. Округлите эту сумму в меньшую сторону, остаток отложите в копилку. Вы можете отправить на накопления 73 ₽ или 273 ₽, смотря с какой суммой вам не жалко расстаться.'
            ]
        },
        secondMessage: {
            messageTitle: 'Цели для работы над собой:',
            advices: [
                'Завести копилку или сберегательный счет и отправлять туда «хвостики» денег.',
                'Хотя бы несколько раз в месяц приходить за покупкой только на следующий день после возникновения потребности.',
                'Разобрать шкаф: хорошую одежду подогнать по фигуре в ателье, ношенную обувь обновить в мастерской.',
                '...'
            ]
        }
    },
]
