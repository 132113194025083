import React from 'react';
import classNames from 'classnames/bind';
import styles from './TrackerRules.module.scss';
import decorFooter from '../../assets/images/Tracker/decor-footer.svg';
import backArrow from '../../assets/images/Tracker/back-arrow.svg';
import {NavLink, useNavigate} from "react-router-dom";

const cn = classNames.bind(styles);

const TrackerRules = () => {
    const navigate = useNavigate();

    return (
        <div className={cn("tracker")}>
            <img className={cn("decor-footer")} src={decorFooter} alt=""/>

            <button className={cn("back tracker__back")} onClick={() => navigate(-1)} style={{border: "none"}}>
                <img src={backArrow} alt=""/>
                <span>Назад</span>
            </button>

            <div className={cn("tracker__info")}>
                <h1 className={cn("tracker__info-titl")}>Согласие на обработку персональных данных</h1>
            </div>

            <div className={cn("info__right")}>
                <div className={cn("info__text")}>
                    <p>
                        Настоящим в соответствии с Федеральным законом от 27.07.2006 года № 152-ФЗ «О персональных
                        данных»
                        свободно, своей волей и в своем интересе даю свое безусловное согласие на обработку моих
                        персональных данных {' '}
                        <span className={cn("info__bold-text")}>федеральному государственному бюджетному учреждению «Научно-исследовательский финансовый институт
                    Министерства финансов Российской Федерации» (далее – НИФИ Минфина России), расположенному по адресу:
                        г. Москва, Настасьинский пер., д. 3, стр. 2, ИНН 7710919320, ОГРН 1127746655464</span> (далее –
                        оператор)
                        на обработку моих персональных данных без использования средств автоматизации, а именно: на
                        сбор,
                        запись, систематизацию, накопление, хранение моих персональных данных, уточнение (обновление,
                        изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение
                        персональных данных.
                    </p>
                    <p>
                        Персональные данные - любая информация, относящаяся к определенному или определяемому на
                        основании
                        такой информации физическому лицу.
                    </p>
                    <p>
                        Настоящее Согласие выдано мною на обработку вносимых на сайт <a
                        style={{color: 'black', textDecoration: 'underline'}}
                        href="https://xn--80apaohbc3aw9e.xn--p1ai/">моифинансы.рф</a> моих персональных данных,
                        включая:
                    </p>
                    <p>
                        - E-Mail;
                    </p>
                    <p>
                        Согласие дается Оператору для обработки моих персональных данных в следующих целях:
                    </p>
                    <p>
                        - направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ;
                    </p>
                    <p>
                        - подготовка и направление ответов на мои запросы;
                    </p>
                    <p>
                        - направление в мой адрес информации о мероприятиях/товарах/услугах/работах Оператора.
                    </p>
                    <p>
                        Настоящее согласие действует до момента его отзыва путем направления соответствующего
                        уведомления на
                        электронный адрес <a style={{color: 'black', textDecoration: 'underline'}}
                                             href="mailto:fgpress@nifi.ru">fgpress@nifi.ru</a>
                    </p>
                </div>
            </div>

        </div>
    );
};

export default TrackerRules;