import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames/bind';
import styles from './FinanceHabitsQuestion.module.scss';
import Header from "../../components/Header/Header";
import financeHabitsStrips from '../../assets/images/Header/finance-habits-strips.svg';
import {NavLink, useMatch} from "react-router-dom";
import {financeHabitsData} from "../../data/financeHabits";
import {BadFinanceHabitsContext} from "../../context/financeHabitsContext";

const cn = classNames.bind(styles);

const FinanceHabitsAnswer = ({text, points}) => {
    const [isChecked, setIsChecked] = useState(false);

    const {increaseScore, decreaseScore} = useContext(BadFinanceHabitsContext);

    const togglePoint = (points) => {
        setIsChecked(!isChecked);
        if (isChecked) decreaseScore(points);
        if (!isChecked) increaseScore(points);
    }

    return (
        <>
            <input type="checkbox" className={cn('answer')} id={text} checked={isChecked}
                   onChange={() => togglePoint(points)}/>
            <label htmlFor={text} className={cn('answer__label', {'answer__label--active': isChecked})}>
                {text}
            </label>
        </>
    );
};


const FinanceHabitsQuestion = () => {
    const [question, setQuestion] = useState({});
    const {params} = useMatch('finance-habits/:questionNumber');

    useEffect(() => {
        const currentQuestion = financeHabitsData.filter(i => i.id === +params?.questionNumber);
        setQuestion(currentQuestion[0]);
    }, [params]);

    return (
        <div className={cn('wrapper')}>
            <Header background="#00A555" text={"Трекер вредных \n финансовых привычек"}
                    strips={financeHabitsStrips}/>
            <div className={cn('content')}>
                <div className={cn('flex-container')}>
                    <div className={cn('left-block')}>
                        <p className={cn('question-number')}>Вопрос {question?.id} из 6</p>
                        <h2 className={cn('title')}>{question?.title}</h2>
                        <p className={cn('text-up')}>Выберите пункты, которые вас характеризуют:</p>
                        <p className={cn('text-down')}>Ответов может быть несколько.</p>
                        {question?.answers?.map(i =>
                            <FinanceHabitsAnswer key={question.id + i.text} text={i.text} points={i.points}/>
                        )}
                    </div>
                    <div className={cn('right-block')}>
                        <div className={cn('image-container')}>
                            <img src={question?.image} alt=""/>
                        </div>
                        <NavLink className={cn('btn')}
                                 to={`/finance-habits/${+params.questionNumber <= 5
                                     ? +params.questionNumber + 1
                                     : 'result'}`}
                        >
                            Следующий вопрос
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinanceHabitsQuestion;