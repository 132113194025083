import React, {useEffect, useState, useContext} from 'react';
import classNames from 'classnames/bind';
import styles from './FinanceQuizzes.module.scss';
import {NavLink, useMatch} from 'react-router-dom';
import Header from "../../components/Header/Header";
import financeStrips from '../../assets/images/Header/finance-quizzes-strips.png';
import num1 from '../../assets/images/FinanceQuizzes/num1.svg';
import num2 from '../../assets/images/FinanceQuizzes/num2.svg';
import num3 from '../../assets/images/FinanceQuizzes/num3.svg';
import num4 from '../../assets/images/FinanceQuizzes/num4.svg';
import main1 from '../../assets/images/FinanceQuizzes/finance-main-1.jpg';
import main2 from '../../assets/images/FinanceQuizzes/finance-main-2.jpg';
import main3 from '../../assets/images/FinanceQuizzes/finance-main-3.jpg';
import main4 from '../../assets/images/FinanceQuizzes/finance-main-4.jpg';

const cn = classNames.bind(styles);

const FinanceQuizzes = () => {

    return (
        <div className={cn('wrapper')}>
            <Header background="#EE7D00" text="Финансовые загадки" strips={financeStrips}/>
            <div className={cn('content')}>
                <div className={cn('title')}>Выберите загадку</div>
                <div className={cn('grid-container')}>
                    <NavLink to="/finance-quizzes/1" className={cn('grid-item')}>
                        <img className={cn('small-img')} src={num1} alt=""/>
                        <img className={cn('big-img')} src={main1} alt=""/>
                    </NavLink>
                    <NavLink to="/finance-quizzes/2" className={cn('grid-item')} >
                        <img className={cn('big-img')} src={main2} alt=""/>
                        <img className={cn('small-img')} src={num2} alt=""/>
                    </NavLink>
                    <NavLink to="/finance-quizzes/3" className={cn('grid-item')} >
                        <img className={cn('small-img')} src={num3} alt=""/>
                        <img className={cn('big-img')} src={main3} alt=""/>
                    </NavLink>
                    <NavLink to="/finance-quizzes/4" className={cn('grid-item')} >
                        <img className={cn('big-img')} src={main4} alt=""/>
                        <img className={cn('small-img')} src={num4} alt=""/>
                    </NavLink>
                </div>
            </div>
        </div>

    );
};

export default FinanceQuizzes;