import React, {useState, useContext} from 'react';
import './TrackerAdvices.scss';
import decorFooter from '../../assets/images/Tracker/decor-footer.svg';
import logoFinance from '../../assets/images/Tracker/logo-finance.png';
import backArrow from '../../assets/images/Tracker/back-arrow.svg';
import wheel from '../../assets/images/Tracker/left-wheel.png';
import {NavLink, useNavigate} from "react-router-dom";
import greenCross from "../../assets/images/Keyboard/green-cross.svg";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import KeyBoard from "../../components/KeyBoard/KeyBoard";
import EmailModal from "../../components/EmailModal/EmailModal";


const TrackerSecondAdvices = () => {
    const [isEmailOpened, setIsEmailOpened] = useState(false);
    const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [typeKeyboard, setTypeKeyboard] = useState('');
    const [data, setData] = useState("");

    const navigate = useNavigate();

    const openModalWindow = () => {
        setIsEmailOpened(true);
        setData('')
    }

    return (
        <div className="tracker">
            <img className="decor-footer" src={decorFooter} alt=""/>
            <div className="tracker__logo">
                <img src={logoFinance} alt=""/>
            </div>

            <NavLink to="/tracker/advices-1" className="back tracker__back">
                <img src={backArrow} alt=""/>
                <span>Назад</span>
            </NavLink>

            <div className="tracker__info">
                <h1 className="tracker__info-title">Трекер ФинЗОЖ</h1>
                <img src={wheel} alt=""/>
            </div>

            <div className="info__right">
                <h2 className="info__right-title">МоиФинансы подготовили правила финансового здоровья. Они помогут
                    добавить к своим ежедневным ритуалам полезные привычки.</h2>
                <div className="info__right-inner">
                    <div className="info__list">
                        <h2 className="info__title">
                            <div className="info__title-num">12</div>
                            <span>Не забывайте об одном из лучших вариантов инвестиций – в свое образование и здоровье</span>
                        </h2>
                        <p className="info__detail">
                            Приобретайте новые навыки - так вы повышаете свою конкурентоспособность на рынке труда. А
                            что до здоровья – оно похоже на банковский счет, который важно пополнять, а не только
                            расходовать. </p>
                        <h2 className="info__title">
                            <div className="info__title-num">13</div>
                            <span>Не стесняйтесь и не жалейте времени, чтобы поискать лучшее предложение</span>
                        </h2>
                        <p className="info__detail">
                            Время, затраченное на анализ рынка – это сэкономленные в результате деньги.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">14</div>
                            <span>Диверсифицируйте свои инвестиции и сбережения</span>
                        </h2>
                        <p className="info__detail">
                            Не держите все яйца в одной корзине – так вы снизите риски.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">15</div>
                            <span>Критично оценивайте рекламу финансовых услуг</span>
                        </h2>
                        <p className="info__detail">
                            И банки, и брокеры – бизнес. Не ждите от них альтруизма, а от их сотрудников – решения ваших
                            проблем.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">16</div>
                            <span>Участвуйте в программах господдержки и помните про налоговый вычет</span>
                        </h2>
                        <p className="info__detail">
                            Следите за новостями, будьте в курсе льгот, субсидий, налоговых вычетов и прочих мер
                            поддержки. Не теряйте то, что положено вам по закону.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">17</div>
                            <span>Участвуйте в общественных решениях, куда направить бюджетные средства</span>
                        </h2>
                        <p className="info__detail">
                            Инициативное бюджетирование – это реальная возможность сделать жизнь удобнее за счет средств
                            из госбюджета.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">18</div>
                            <span>Делитесь своими финансовыми знаниями и опытом с другими</span>
                        </h2>
                    </div>
                    <div className="info__list">
                        <p className="info__detail">Не стесняйтесь, ведь так ваши знания и опыт принесут гораздо больше
                            пользы.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">19</div>
                            <span>Платите налоги, покупайте лицензионное, требуйте чеки</span>
                        </h2>
                        <p className="info__detail">
                            Пусть это станет вашим осознанным выбором. Только так мы вместе сформируем новую финансовую
                            культуру</p>
                        <h2 className="info__title">
                            <div className="info__title-num">20</div>
                            <span>Участвуйте в благотворительности</span>
                        </h2>
                        <p className="info__detail">
                            Пожертвования, волонтёрство, а также другая ваша помощь – меняют мир к лучшему и могут стать
                            частью вашего финансового плана.</p>
                    </div>
                </div>
                <div className="info__email-send">
                    <p>Рекомендуем распечатать наш чек-лист и повесить на видное место (например, холодильник)</p>
                    <p>Прислать чек-лист на почту?</p>
                    <div className="info__email-btns">
                        <button className="info__email-btn-green" onClick={() => openModalWindow()}>
                            Да
                        </button>
                        <button className="info__email-btn-red" onClick={() => {
                            navigate('/');
                            window.location.reload()
                        }}>
                            Нет
                        </button>
                    </div>
                </div>
            </div>
            {!isKeyboardOpened
                ?
                <ModalWindow windowState={isEmailOpened} setModal={setIsEmailOpened} cross={greenCross}
                             stripColor="#EF7D00">
                    <EmailModal setIsKeyboardOpened={setIsKeyboardOpened} emailValue={emailValue}
                                setEmailValue={setEmailValue} setIsEmailOpened={setIsEmailOpened}
                                setTypeKeyboard={setTypeKeyboard} type="list"/>
                </ModalWindow>
                :
                <ModalWindow windowState={isKeyboardOpened} setModal={setIsKeyboardOpened} cross={greenCross}
                             stripColor="#EF7D00">
                    <KeyBoard emailValue={emailValue} setEmailValue={setEmailValue}
                              setIsKeyboardOpened={setIsKeyboardOpened} typeKeyboard={typeKeyboard}/>
                </ModalWindow>
            }
        </div>
    );
};

export default TrackerSecondAdvices;