import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './RegionsMap.module.scss';
import Header from "../../components/Header/Header";
import bestProjectsStrips from '../../assets/images/Header/best-projects-strips.png'
import { YMaps, Map } from "react-yandex-maps";
import axios from 'axios';
import MapComponent from './MapComponent';
import loader from '../../assets/images/loader.svg';

const cn = classNames.bind(styles);

const RegionsMap = () => {
	const [regions, setRegions] = useState([])
	const [filters, setFilters] = useState({
		releaseYear: null,
		region: null,
		projects: null,
		peopleCount: null,
		projectCostRange: null,
	})


	const sortRegions = (field) => {
		return (a, b) => (b[field] > a[field] ? 1 : -1);
	}

	const fetchRegions = async () => {
		const address = 'https://app-dev.xn--80apaohbc3aw9e.xn--p1ai/api/module/map'
		const colors = ['#43A99E', '#4FC4BB', '#80D4CF', '#B3E5E2', '#D9F2F0']

		const { data: { data } } = await axios.get(address)

		data
			.sort(sortRegions('projects_count'))
			.map((el, i) => el.color = (colors[Math.floor(i / 17)] ? colors[Math.floor(i / 17)] : '#43A99E'));
			const collator = new Intl.Collator();
			data.sort((a, b) => collator.compare(a.title, b.title));

		setRegions(data)
	}

	const detail = (id) => {
		setFilters({ ...filters, region: [id] })

		this.setGetParams({ region: id });
		this.fetchProjects();
	}

	useEffect(() => {
		fetchRegions()
	}, [])

	return (
		<>
			<div className={cn('wrapper')}>
				<Header background="#014F9E" text="Карта регионов"
					strips={bestProjectsStrips} />
				<div className={cn("map-container")}>
					{
						regions.length
							? <MapComponent regions={regions} />
							: <div className={cn('loading')}>Loading...</div>
					}
				</div>
			</div>
		</>
	);
};

export default RegionsMap;