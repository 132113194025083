import classNames from 'classnames/bind';
import styles from './RegionsMap.module.scss';
import { NavLink } from 'react-router-dom';

const cn = classNames.bind(styles);

const MapComponent = ({ regions, detail }) => {
	return (
		<svg id="svgMap" width="1224.449" height="760.6203" viewBox="0 0 1224.449 760.6203" className={cn("map")}>
			{regions.map((region, index) =>
				<NavLink to={`/regions/${region.slug}`} key={region.id}>
					<path
						id={'path-' + index}
						className="path"
						d={region.map_path}
						fill={region.color}
						
					>
						<title>{region.title}. Проектов: {region.projects_count}</title>
					</path>
				</NavLink>
			)}
		</svg>
	);
};

export default MapComponent;