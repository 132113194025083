import React from 'react';
import './TrackerAdvices.scss';
import decorFooter from '../../assets/images/Tracker/decor-footer.svg';
import logoFinance from '../../assets/images/Tracker/logo-finance.png';
import backArrow from '../../assets/images/Tracker/back-arrow.svg';
import wheel from '../../assets/images/Tracker/left-wheel.png';
import {NavLink} from "react-router-dom";

const TrackerFirstAdvices = () => {

    return (
        <div className="tracker">
            <img className="decor-footer" src={decorFooter} alt=""/>
            <div className="tracker__logo">
                <img src={logoFinance} alt=""/>
            </div>

            <NavLink to="/tracker/result" className="back tracker__back">
                <img src={backArrow} alt=""/>
                <span>Назад</span>
            </NavLink>

            <div className="tracker__info">
                <h1 className="tracker__info-title">Трекер ФинЗОЖ</h1>
                <img src={wheel} alt=""/>
            </div>

            <div className="info__right">
                <h2 className="info__right-title">МоиФинансы подготовили правила финансового здоровья. Они помогут
                    добавить к своим ежедневным ритуалам полезные привычки.</h2>
                <div className="info__right-inner">
                    <div className="info__list">
                        <h2 className="info__title">
                            <div className="info__title-num">1</div>
                            <span>Инвестируя, рискуйте только тем, что готовы потерять</span>
                        </h2>
                        <p className="info__detail">
                            Инвестиции – это всегда риск, поэтому вкладывайте только те средства, потеря которых не
                            будет фатальна для
                            вашего благосостояния. Никогда не инвестируйте заемные средства.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">2</div>
                            <span>Формируйте финансовую подушку при любых доходах</span>
                        </h2>
                        <p className="info__detail">
                            Откладывайте хоть сколько-нибудь. Важна привычка регулярно сберегать.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">3</div>
                            <span>Тщательно продумайте, где хранить сбережения – дома или в банке</span>
                        </h2>
                        <p className="info__detail">
                            Помните: 1) государство страхует ваш банковский вклад до 1,4 млн рублей; 2) дома на деньги
                            не капает никакой
                            процент. </p>
                        <h2 className="info__title">
                            <div className="info__title-num">4</div>
                            <span>Не отдавайте деньги тем, кто обещает сверхдоход</span>
                        </h2>
                        <p className="info__detail">
                            Помните, бесплатный сыр, как и обещания доходов в разы больше среднерыночных, бывает только
                            в мышеловке.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">5</div>
                            <span>Воздержитесь от вложений, если не понимаете, как они будут работать</span>
                        </h2>
                        <p className="info__detail">
                            Голословные заверения в надежности и доходности – признак обмана, если к ним не приложена
                            финансовая
                            отчетность, внятная информация о собственниках, государственная лицензия.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">6</div>
                            <span>Пользуйтесь займами в МФО лишь на короткий срок</span>
                        </h2>
                        <p className="info__detail">
                            Микрофинансовая организация дает деньги под высокие проценты. Взять деньги в МФО проще, чем
                            в банке или у
                            работодателя, но выплачивать придется суммы, которые могут оказаться непосильными. Поэтому
                            прибегайте к
                            займам в МФО только в крайнем случае.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">7</div>
                            <span>Требуйте разъяснять вам все пункты договора с финансовой организацией</span>
                        </h2>
                    </div>
                    <div className="info__list">
                        <p className="info__detail">Любой договор, который вам предстоит подписать, нужно тщательно
                            изучить. Прочитайте
                            все, включая самый мелкий шрифт, и, если вам что-то непонятно или не устраивает вас,
                            обсудите с банком
                            условия. В договоре участвуют две стороны, не упустите возможность договориться о
                            корректировке в вашу
                            пользу.</p>
                        <h2 className="info__title">
                            <div className="info__title-num">8</div>
                            <span>Не решайте финансовые вопросы в телефонных разговорах или переписке с незнакомцами</span>
                        </h2>
                        <p className="info__detail">
                            Кем бы они ни представлялись и какие бы проблемы ни озвучивали – не сообщайте личные данные
                            ни под каким
                            предлогом. Чтобы не потерять свои сбережения, перезвоните в финансовую организацию
                            самостоятельно, проверьте
                            информацию и только после этого принимайте решения. </p>
                        <h2 className="info__title">
                            <div className="info__title-num">9</div>
                            <span>Говорите нет, если вас торопят или пугают последствиями </span>
                        </h2>
                        <p className="info__detail">
                            Создать спешку или напугать – самые распространенные способы манипуляции: их используют
                            мошенники. Не идите
                            на поводу у эмоций, сохраняйте скептицизм и спокойствие</p>
                        <h2 className="info__title">
                            <div className="info__title-num">10</div>
                            <span>Привыкайте контролировать свои деньги </span>
                        </h2>
                        <p className="info__detail">
                            Ведите бюджет, планируйте расходы и доходы. Для начала просто записывайте все траты изо дня
                            в день. Вы
                            быстро увидите, от каких расходов можно отказаться, и начнёте разумно экономить. Сегодня
                            есть много цифровых
                            решений, позволяющих вести личный бюджет, многие банки уже встроили подобные трекеры в свои
                            мобильные
                            приложения. </p>
                        <h2 className="info__title">
                            <div className="info__title-num">11</div>
                            <span>Удлиняйте горизонт своего финансового планирования</span>
                        </h2>
                        <p className="info__detail">
                            Кроме разумной экономии, это предоставит вам возможность пользоваться финансовыми
                            продуктами, которые на
                            длинной дистанции дают более высокую доходность</p>
                    </div>
                </div>
                <NavLink className="info__btn-next" to="/tracker/advices-2">ДАЛЕЕ</NavLink>
            </div>

        </div>
    );
};

export default TrackerFirstAdvices;