import img1 from '../assets/images/FinanceQuizzes/quizz-1.jpg';
import img2 from '../assets/images/FinanceQuizzes/quizz-2.jpg';
import img3 from '../assets/images/FinanceQuizzes/quizz-3.jpg';
import img4 from '../assets/images/FinanceQuizzes/quizz-4.jpg';
import qr1 from '../assets/images/FinanceQuizzes/qr-1.svg';
import qr2 from '../assets/images/FinanceQuizzes/qr-2.svg';
import qr3 from '../assets/images/FinanceQuizzes/qr-3.svg';
import qr4 from '../assets/images/FinanceQuizzes/qr-4.svg';

export const quizzQuestions = [
    {
        id: 1,
        text: 'Арбуз сначала подорожал на  10%, а потом подешевел на 10%. Сколько стал стоить арбуз?',
        image: img1,
        qr: qr1
    },
    {
        id: 2,
        text: 'Какое слово из области финансов связывает эти две картинки?',
        image: img2,
        qr: qr2
    },
    {
        id: 3,
        text: 'Какая сказочная героиня на легкие деньги выгодно купила вещь для вечеринки?',
        image: img3,
        qr: qr3
    },
    {
        id: 4,
        text: 'Как назывался первый в России банк?',
        image: img4,
        qr: qr4
    },
]