import React, {useContext, useEffect} from 'react';
import classNames from 'classnames/bind';
import styles from './MainPage.module.scss';
import MainBlock from "../../components/MainBlock/MainBlock";
import {cardsData} from "../../data/mainPage";
import {BadFinanceHabitsContext} from "../../context/financeHabitsContext";
import {PushkinContext} from "../../context/pushkinTestContext";

const cn = classNames.bind(styles);

const MainPage = () => {
    const {clearScore} = useContext(BadFinanceHabitsContext);
    const {clearResult} = useContext(PushkinContext);

    useEffect(() => {
        clearScore();
        clearResult();
    }, [])

    return (
        <div className={cn('wrapper')}>
            <div className={cn('grid-container')}>
                {cardsData.map(i => <MainBlock
                    key={i.text}
                    url={i.url}
                    color={i.color}
                    text={i.text}
                    btn={i.btn}
                />)}
            </div>
        </div>
    );
};

export default MainPage;