import React from 'react';
import classNames from 'classnames/bind';
import styles from './BestParcticesBlock.module.scss';
import top from '../../assets/images/BestPractices/top-line.svg';
import center from '../../assets/images/BestPractices/center-line.svg';
import bottom from '../../assets/images/BestPractices/bottom-line.svg';
import {NavLink} from "react-router-dom";

const cn = classNames.bind(styles);

const BestPracticesBlock = ({side, position, text, link}) => {
    return (
        <div className={cn('block', `block-${side}`)}>

            {position === 'top' && side === 'left' &&
                <img className={cn('line')} style={{top: '50%', right: -120}} src={top} alt=""/>}
            {position === 'center' && side === 'left' &&
                <img className={cn('line')} style={{top: '50%', right: -75}} src={center} alt=""/>}
            {position === 'bottom' && side === 'left' &&
                <img className={cn('line')} style={{top: 0, right: -120}} src={bottom} alt=""/>}

            {position === 'top' && side === 'right' &&
                <img className={cn('line', 'line-left')} style={{top: '50%', left: -130}} src={top} alt=""/>}
            {position === 'center' && side === 'right' &&
                <img className={cn('line', 'line-left')} style={{top: '50%', left: -75}} src={center} alt=""/>}
            {position === 'bottom' && side === 'right' &&
                <img className={cn('line', 'line-left')} style={{top: 0, left: -120}} src={bottom} alt=""/>}

            <NavLink to={link}>
                {text}
            </NavLink>
        </div>
    );
};

export default BestPracticesBlock;