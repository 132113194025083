import smartSchool1 from "../assets/images/BestProjects/smart-school-1.jpg";
import smartSchool2 from "../assets/images/BestProjects/smart-school-2.jpg";
import smartSchool3 from "../assets/images/BestProjects/smart-school-3.jpg";
import orion1 from "../assets/images/BestProjects/orion-1.jpg";
import orion2 from "../assets/images/BestProjects/orion-2.jpg";
import orion3 from "../assets/images/BestProjects/orion-3.jpg";
import ilinskaya1 from "../assets/images/BestProjects/ilinskaya-1.jpg";
import ilinskaya2 from "../assets/images/BestProjects/ilinskaya-2.jpg";
import ilinskaya3 from "../assets/images/BestProjects/ilinskaya-3.jpg";
import fastovetskaya1 from "../assets/images/BestProjects/fastovetskaya-1.jpg";
import fastovetskaya2 from "../assets/images/BestProjects/fastovetskaya-2.jpg";
import fastovetskaya3 from "../assets/images/BestProjects/fastovetskaya-3.jpg";
import kovcheg1 from "../assets/images/BestProjects/kovcheg-1.jpg";
import kovcheg2 from "../assets/images/BestProjects/kovcheg-2.jpg";
import kovcheg3 from "../assets/images/BestProjects/kovcheg-3.jpg";
import miniatur1 from "../assets/images/BestProjects/miniatur-1.jpg";
import miniatur2 from "../assets/images/BestProjects/miniatur-2.jpg";
import miniatur3 from "../assets/images/BestProjects/miniatur-3.jpg";
import perezagruzka1 from "../assets/images/BestProjects/perezagruzka-1.jpg";
import perezagruzka2 from "../assets/images/BestProjects/perezagruzka-2.jpg";
import perezagruzka3 from "../assets/images/BestProjects/perezagruzka-3.jpg";
import kovorking1 from "../assets/images/BestProjects/kovorking-1.jpg";
import kovorking2 from "../assets/images/BestProjects/kovorking-2.jpg";
import kovorking3 from "../assets/images/BestProjects/kovorking-3.jpg";
import chelovechnost1 from "../assets/images/BestProjects/chelovechnost-1.jpg";
import chelovechnost2 from "../assets/images/BestProjects/chelovechnost-2.jpg";
import chelovechnost3 from "../assets/images/BestProjects/chelovechnost-3.jpg";
import community1 from "../assets/images/BestProjects/community-1.jpg";
import community2 from "../assets/images/BestProjects/community-2.jpg";
import community3 from "../assets/images/BestProjects/community-3.jpg";
import pic1 from "../assets/images/BestProjects/1.jpg";
import pic2 from "../assets/images/BestProjects/2.jpg";
import pic3 from "../assets/images/BestProjects/3.jpg";
import pic4 from "../assets/images/BestProjects/4.jpg";
import pic5 from "../assets/images/BestProjects/5.jpg";
import pic6 from "../assets/images/BestProjects/6.jpg";
import pic7 from "../assets/images/BestProjects/7.jpg";
import pic8 from "../assets/images/BestProjects/8.jpg";
import pic9 from "../assets/images/BestProjects/9.jpg";
import pic10 from "../assets/images/BestProjects/10.jpg";

export const mainData = [
    {id: 1, name: 'Интеллектуальная система «Умная школа»', location: 'Ленинградская область, г. Тосно.', pic: pic1},
    {id: 2, name: 'Веревочный парк «ОРИОН»', location: 'Свердловская область, пгт. Верхняя Синячиха.', pic: pic2},
    {id: 3, name: 'Благоустройство сквера в \n ст. Ильинская', location: 'Краснодарский край, станица Ильинская.', pic: pic3},
    {id: 4, name: 'Благоустройство парка и общественной территории в станице Фастовецкая', location: 'Краснодарский край, станица Фастовецкая.', pic: pic4},
    {id: 5, name: 'Топиарный парк «Ноев ковчег»', location: 'ХМАО, г. Пыть-Ях.', pic: pic5},
    {id: 6, name: 'Интерактивный парк миниатюр', location: 'Липецкая область, село Кузьминские Отвержки.', pic: pic6},
    {id: 7, name: 'Проект «Школьная перемена. Перезагрузка»', location: 'Волгоградская область, г. Волгоград.', pic: pic7},
    {id: 8, name: 'Коворкинг-пространство \n «АРТ-коворкинг»', location: 'ХМАО, село Локосово.', pic: pic8},
    {id: 9, name: '«ЧелоВечность» (проект устройства туристической археологической тропы на территории урочища «Барсова гора»)', location: 'ХМАО, Сургутский район, пгт. Барсово.', pic: pic9},
    {id: 10, name: 'Районный медиаклуб для школьников и молодежи «CommUNITY»', location: 'Удмуртская Республика, поселок Ува.', pic: pic10},
]

export const modalData = [
    {
        id: 1,
        title: 'Интеллектуальная система «Умная школа»',
        place: 'Ленинградская область, г. Тосно.',
        description: 'Реализован проект по модернизации кабельных сетей и сетевого оборудования в гимназии №2 г. Тосно.',
        amount: '2 500 человек',
        images: {
            img1: smartSchool1,
            img2: smartSchool2,
            img3: smartSchool3,
        }
    },
    {
        id: 2,
        title: 'Веревочный парк «ОРИОН»',
        place: 'Свердловская область, пгт. Верхняя Синячиха.',
        description: 'Реализован проект культурно-досугового и физкультурно-оздоровительного назначения — веревочный парк.',
        amount: '18 292 человека',
        images: {
            img1: orion1,
            img2: orion2,
            img3: orion3,
        }
    },
    {
        id: 3,
        title: 'Благоустройство сквера в \n ст. Ильинская',
        place: 'Краснодарский край, станица Ильинская.',
        description: 'Реализован проект по благоустройству сквера «Молодежный» в станице Ильинская. Был улучшен внешний облик населенного пункта, внедрены новые приемы и методы оформления сельской среды.',
        amount: '3 848 человек',
        images: {
            img1: ilinskaya1,
            img2: ilinskaya2,
            img3: ilinskaya3,
        }
    },
    {
        id: 4,
        title: 'Благоустройство парка и общественной территории в станице Фастовецкая',
        place: 'Краснодарский край, станица Фастовецкая.',
        description: 'Реализован проект по реконструкции и благоустройству парка и общественной территории в станице Фастовецкая.',
        amount: '9 525 человек',
        images: {
            img1: fastovetskaya1,
            img2: fastovetskaya2,
            img3: fastovetskaya3,
        }
    },
    {
        id: 5,
        title: 'Топиарный парк «Ноев ковчег»',
        place: 'ХМАО, г. Пыть-Ях.',
        description: 'Жителями города Пыть-Ях был создан тематический топиарный парк «Ноев ковчег» с фигурами животных и уникальными дизайнерскими формами. Топиарный парк — это не просто красивое место для отдыха горожан, но и пространство для организации широкого спектра мероприятий: открытых уроков на природе, творческих фестивалей и выступлений, выставок, экологических акций.',
        amount: '39 828 человек',
        images: {
            img1: kovcheg1,
            img2: kovcheg2,
            img3: kovcheg3,
        }
    },
    {
        id: 6,
        title: 'Интерактивный парк миниатюр',
        place: 'Липецкая область, село Кузьминские Отвержки.',
        description: 'Жителями села был самостоятельно реализован проект по созданию уникального интерактивного парка миниатюр в селе Кузьминские Отвержки. В парке дети и жители района могут играть в оригинальные игры на свежем воздухе и знакомиться с произведениями мировой архитектуры в миниатюре.',
        amount: '6 000 человек',
        images: {
            img1: miniatur1,
            img2: miniatur2,
            img3: miniatur3,
        }
    },
    {
        id: 7,
        title: 'Проект «Школьная перемена. Перезагрузка»',
        place: 'Волгоградская область, г. Волгоград.',
        description: 'По собственной инициативе и при участии школьников был реализован проект по созданию современного пространства для отдыха и развития учащихся в средней школе №3 города Волгоград.',
        amount: '1 520 человек',
        images: {
            img1: perezagruzka1,
            img2: perezagruzka2,
            img3: perezagruzka3,
        }
    },
    {
        id: 8,
        title: 'Коворкинг-пространство \n «АРТ-коворкинг»',
        place: 'ХМАО, село Локосово.',
        description: '«АРТ-коворкинг» — это многофункциональное пространство для творческого развития и отдыха жителей села Локосово, где проводятся мастер-классы, воркшопы по различным направлениям и творческие встречи.',
        amount: '1 589 человек',
        images: {
            img1: kovorking1,
            img2: kovorking2,
            img3: kovorking3,
        }
    },
    {
        id: 9,
        title: '«ЧелоВечность» (проект устройства туристической археологической тропы на территории урочища \n «Барсова гора»)',
        place: 'ХМАО, Сургутский район, пгт. Барсово.',
        description: 'В рамках проекта «ЧелоВечность» создано семь арт-объектов — копии артефактов, найденных в недрах земли Барсовой горы: фигура шерстистого носорога, филина, тотемные символы. Теперь уникальные копии артефактов доступны каждому, узнать подробнее об артефактах можно с помощью QR-кода и специально созданного мобильного приложения «ЧелоВечность».',
        amount: '597 человек',
        images: {
            img1: chelovechnost1,
            img2: chelovechnost2,
            img3: chelovechnost3,
        }
    },
    {
        id: 10,
        title: 'Районный медиаклуб для школьников и молодежи «CommUNITY»',
        place: 'Удмуртская Республика, поселок Ува.',
        description: 'Медиаклуб «CommUNITY» был создан с целью обучения детей и молодежи современным цифровым профессиям. Это возможность для ребят ребятам примерить роли медиаволонтера, корреспондента, фотографа, видеооператора, монтажера, звукорежиссера, верстальщика, дизайнера, а также обеспечить информационное освещение социальным и добровольческим проектам, событиям и мероприятиям района.',
        amount: '1 439 человек',
        images: {
            img1: community1,
            img2: community2,
            img3: community3,
        }
    },
]



