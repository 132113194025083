import img1 from '../assets/images/BestPractices/1.png';
import img2 from '../assets/images/BestPractices/2.png';
import img3 from '../assets/images/BestPractices/3.png';
import img4 from '../assets/images/BestPractices/4.png';
import img5 from '../assets/images/BestPractices/5.png';
import img6 from '../assets/images/BestPractices/6.png';
import img7 from '../assets/images/BestPractices/7.png';
import img8 from '../assets/images/BestPractices/8.png';
import img9 from '../assets/images/BestPractices/9.png';
import img10 from '../assets/images/BestPractices/10.png';
import img11 from '../assets/images/BestPractices/11.png';
import img12 from '../assets/images/BestPractices/12.png';

export const bestPractices = [
    {
        id: 1,
        title: 'Специальные проекты со СМИ, блогерами и в соцсетях',
        items: [
            {
                title: 'Информационная кампания по финграмотности',
                text: 'Полезная информация размещена в качестве рекламного блока на единых платежных документах по оплате ЖКУ.',
                audience: 'взрослое население.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'Комитет по печати Ленинградской области.',
                field: 'Ленинградская область',
                image: img1,
            },
            {
                title: 'Финансовая журналистика ',
                text: 'Организован открытый дискуссионный клуб «Дружи с финансами», где ответы на вопросы жителей дают признанные профессионалы и эксперты федерального и регионального уровня.',
                audience: 'молодежь, взрослое население.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'ТРО ОООП «Союз защиты прав потребителей финансовых услуг».',
                field: 'Тюменская область',
                image: img2,
            },
        ]
    },
    {
        id: 2,
        title: 'Спецпроекты с органами соцзащиты, МФЦ, ПФР, ФНС, Роспотребнадзором и др.',
        items: [
            {
                title: 'Еженедельное обучение по составлению бизнес-планов для получения социальных контрактов ',
                text: 'Организовано еженедельное бесплатное двухчасовое обучение по составлению бизнес-плана в Центре «Мой бизнес» «методом Сократа». Через 2 часа участники получают свой бизнес-план, финансовую модель и сформулированное ценностное предложение.',
                audience: 'взрослое население.',
                coverage: 'от 100 до 1 000 чел./год.',
                developer: 'Автономная некоммерческая организация «Центр поддержки предпринимательства Смоленской области».',
                field: 'Смоленская область',
                image: img3,
            },
            {
                title: 'Региональная школа финансовой грамотности «ФинансУм»',
                text: 'Запущена школа профориентации старшеклассников, нацеленная на развитие финансовой грамотности школьников.',
                audience: 'школьники.',
                coverage: 'от 100 до 1 000 чел./год.',
                developer: 'ГОУ ДО Тульской области «Центр дополнительного образования детей».',
                field: 'Тульская область',
                image: img4,
            },
        ]
    },
    {
        id: 3,
        title: 'Спецпроекты с общественными организациями и движениями',
        items: [
            {
                title: 'Включение вопросов финансовой грамотности в повестку массовых региональных мероприятий',
                text: 'Тема финансового просвещения встраивается в масштабные региональные проекты: «Созвездие IQ - Самарский НАНОГРАД», Форум «iВолга» по разным направлениям: проводятся лекционные мероприятия, интерактивные игровые, соревновательные мероприятия.',
                audience: 'школьники, студенты СПО и ВПО, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ.',
                coverage: 'от 1 000 до 5 000 чел./год.',
                developer: 'Министерство образования и науки Самарской области, Отделение по Самарской области Волго-Вятского главного управления Банка России.',
                field: 'Самарская область',
                image: img5,
            },
            {
                title: 'Просветительские мероприятия по финансовой грамотности для социально уязвимых групп населения',
                text: 'Организация для граждан, попавших в трудную жизненную ситуацию (лица без определенного места жительства, бывшие нарко- и алкозависимые), краткосрочных курсов по базовым вопросам в области финансовой грамотности.',
                audience: 'взрослое население.',
                coverage: 'от 100 до 1 000 чел./год.',
                developer: 'Минфин Калининградской области (РЦФГ), КРОО «Сила людей».',
                field: 'Калининградская область',
                image: img6,
            },
        ]
    },
    {
        id: 4,
        title: 'Специальные проекты для людей с ограниченными возможностями здоровья',
        items: [
            {
                title: '«Финансовая культура» шрифтом Брайля',
                text: 'Изготовление 75 экземпляров специализированного издания по финансовой грамотности для слепых и слабовидящих, выполненного шрифтом Брайля.',
                audience: 'люди с ОВЗ.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'Отделение по Ставропольскому краю Южного главного управления Банка России.',
                field: 'Ставропольский край',
                image: img7,
            },
            {
                title: 'ФинТьютор',
                text: 'Мобильный видеоучебник финансовой грамотности для педагогов, волонтеров финансового просвещения, а также подростков и молодежи в социальных сетях: «Вконтакте», «Yappu», «ЯRUS», «Rutube». Проект носит инклюзивный характер.',
                audience: 'школьники, студенты СПО и ВПО, взрослое население, люди с ОВЗ.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'Отделение по Рязанской области Главного управления Банка России по Центральному федеральному округу, Региональный центр финансовой грамотности Рязанского института развития образования.',
                field: 'Рязанская область',
                image: img8,
            },
        ]
    },
    {
        id: 5,
        title: 'Специальные проекты для людей старшего возраста',
        items: [
            {
                title: 'Школа финансовой грамотности',
                text: 'Занятия по базовым вопросам финансовой грамотности для пенсионеров и предпенсионеров. В качестве спикеров выступают сотрудники учреждений СОН или привлеченные специалисты кредитных организаций.',
                audience: 'взрослое население, пенсионеры и предпенсионеры.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'Департамент социального развития Тюменской области.',
                field: 'Тюменская область',
                image: img9,
            },
            {
                title: 'Серия выездных информационных площадок для безработных',
                text: 'Серия мероприятий, направленных на информирование граждан, находящихся в поиске работы, о федеральных и региональных мерах поддержки. Формат мероприятий: лекториум – нон-стоп-семинары; консультации.',
                audience: 'взрослое население.',
                coverage: 'от 1 000 до 5 000 чел./год.',
                developer: 'Центр развития налоговой культуры и финансовой грамотности Министерства финансов Ульяновской области.',
                field: 'Ульяновская область',
                image: img10,
            },
        ]
    },
    {
        id: 6,
        title: ' Цифровые продукты',
        items: [
            {
                title: 'Проекты «Журнал Винвестора» и «Школа Винвестора»',
                text: 'Комплекс обучающих материалов и видеокурсов от квалифицированных финансовых и инвестиционных советников, который помогает взять под контроль личные финансы и защитить накопления.',
                audience: 'взрослое население, представители СМП и самозанятые граждане, пенсионеры и предпенсионеры, студенты СПО и ВПО.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'АО «Винвестор».',
                field: 'Ростовская область',
                image: img11,
            },
            {
                title: 'Модуль «Я – начинающий инвестор» сервиса «Цифровая платформа финансовой грамотности населения Кузбасса»',
                text: 'Раздел регионального портала по финансовой грамотности Кузбасса состоящей из  статей, видеоуроков, тестов для получения начальных знаний и практических навыков по осуществлению инвестиционной деятельности.',
                audience: 'старшие школьники, студенты СПО и ВПО, взрослое население, пенсионеры и предпенсионеры, представители СМП и самозанятые граждане, люди с ОВЗ.',
                coverage: 'от 5 000 чел./год и выше.',
                developer: 'Администрация Правительства Кузбасса совместно с Министерством цифрового развития и связи Кузбасса и Региональным центром финансовой грамотности Кузбасса. Разработчик решения - ООО «Веб-студия А42».',
                field: 'Кемеровская область',
                image: img12,
            },
        ]
    },
]



