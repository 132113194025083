import React, {useState} from 'react';
import {createContext} from "react";

export const PushkinContext = createContext();

const PushkinTestContext = ({children}) => {
    const [result, setResult] = useState(0);

    const trueAnswer = () => setResult(prev => prev += 1);
    const falseAnswer = () => setResult(prev => prev);
    const clearResult = () => setResult(0);

    return (
        <>
            <PushkinContext.Provider value={{result, trueAnswer, falseAnswer, clearResult}}>
                {children}
            </PushkinContext.Provider>
        </>
    );
};

export default PushkinTestContext;