import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './fonts.scss';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import PushkinTestContext from "./context/pushkinTestContext";
import TrackerContext from "./context/trackerContext";
import BadFinanceHabitsContext from "./context/financeHabitsContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <PushkinTestContext>
            <TrackerContext>
                <BadFinanceHabitsContext>
                    <App/>
                </BadFinanceHabitsContext>
            </TrackerContext>
        </PushkinTestContext>
    </BrowserRouter>
);
