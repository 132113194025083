import React from 'react';
import classNames from 'classnames/bind';
import styles from './PushkinAnswerItem.module.scss';
import radioIcon from '../../assets/images/Pushkin/radio-icon.svg';
import radioTrue from '../../assets/images/Pushkin/radio-icon-true.svg';
import radioFalse from '../../assets/images/Pushkin/radio-icon-false.svg';

const cn = classNames.bind(styles);

const PushkinAnswerItem = ({id, text, comment, isRight, checkedAnswer, setCheckedAnswer, trueAnswer, falseAnswer}) => {

    const chooseAnswer = () => {
        setCheckedAnswer(id);
        isRight ? trueAnswer() : falseAnswer();
    };

    return (
        <>
            <div className={cn('answer-item')}>
                {
                    checkedAnswer === id
                        ? <img src={isRight ? radioTrue : radioFalse} alt=""/>
                        : <img src={radioIcon} alt="" onClick={() => { if (checkedAnswer === null) chooseAnswer()}}/>
                }
                <div className={cn('answer-text')}>
                    <input name="answer" id={id} type="radio" disabled={checkedAnswer} checked={checkedAnswer === id}
                           onChange={() => chooseAnswer()}/>
                    <label htmlFor={id}>{text}</label>
                </div>
            </div>
            {checkedAnswer === id && <div className={cn('comment')}>{comment}</div>}
        </>
    );
};

export default PushkinAnswerItem;