import React from 'react';
import classNames from 'classnames/bind';
import styles from './TrackerResult.module.scss';
import areas from "../../static/areas.json";

const cn = classNames.bind(styles);


const Answers = ({isCompleted, lowResults, highResults, resultQuality}) => {
    return (
        <>
            {isCompleted &&
                <div id="answers">
                    {isCompleted && <div className="row justify-content-center mt-5">
                        <div className="col-12 col-lg-9">
                            {(+resultQuality === 0 && highResults?.length)
                                ?
                                <div className="greate-resutlt">
                                    <p>
                                        {highResults.map((sector, index) =>
                                            <div key={sector} className={cn('answer-item')}>
                                                {`${areas[sector].answer_title}`}
                                            </div>
                                        )}
                                    </p>
                                </div>
                                :
                                <div></div>
                            }

                            {(+resultQuality === 0 && lowResults?.length)
                                ?
                                <div className="bad-resutlt">
                                    <p>
                                        {lowResults.map((sector, index) =>
                                            <div key={sector} className={cn('answer-item')}>
                                                {`${areas[sector].answer_title}`}
                                            </div>
                                        )}
                                    </p>
                                </div>
                                :
                                <div></div>
                            }
                        </div>
                    </div>}
                </div>
            }
        </>
    );
};

export default Answers;