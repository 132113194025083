import React, {useState} from 'react';
import classNames from 'classnames/bind';
import styles from './BestProjects.module.scss';
import Header from "../../components/Header/Header";
import bestProjectsStrips from '../../assets/images/Header/best-projects-strips.png'
import BestProjectsCard from "../../components/BestProjectsCard/BestProjectsCard";
import {mainData, modalData} from "../../data/bestProjects";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import BestProjectsModal from "../../components/BestProjectsModal/BestProjectsModal";
import redCross from '../../assets/images/BestProjects/red-cross.svg';

const cn = classNames.bind(styles);

const BestProjects = () => {
    const [modal, setModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);

    const openModal = (id) => {
        const modalInfo = modalData.filter(item => item.id === id);
        setModalItem(modalInfo[0]);
        setModal(true);
    };

    return (
        <>
            <div className={cn('wrapper')}>
                <Header background="#014F9E" text="Инициативное бюджетирование – 2022. Лучшие проекты"
                        strips={bestProjectsStrips}/>
                <div className={cn('content')}>
                    {mainData.map(i => <BestProjectsCard key={i.id} id={i.id} pic={i.pic} name={i.name}
                                                         location={i.location} openModal={openModal}/>)}
                </div>
            </div>
            {modalItem &&
                <ModalWindow windowState={modal} setModal={setModal} cross={redCross} stripColor="#014F9E">
                    <BestProjectsModal id={modalItem.id} title={modalItem.title} images={modalItem.images}
                                       place={modalItem.place}
                                       amount={modalItem.amount}
                                       description={modalItem.description} openModal={openModal} />
                </ModalWindow>
            }
        </>
    );
};

export default BestProjects;