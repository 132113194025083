import React, {useContext, useEffect, useRef, useState} from 'react';
import './Wheel.scss';
import wheelOfLife from '../../static/wheel-of-life';
import areas from '../../static/areas.json';
import decorFooter from '../../assets/images/Tracker/decor-footer.svg';
import logoFinance from '../../assets/images/Tracker/logo-finance.png';
import backArrow from '../../assets/images/Tracker/back-arrow.svg';
import track1 from '../../assets/images/Tracker/track1.svg';
import track2 from '../../assets/images/Tracker/track2.svg';
import track3 from '../../assets/images/Tracker/track3.svg';
import track4 from '../../assets/images/Tracker/track4.svg';
import track5 from '../../assets/images/Tracker/track5.svg';
import track6 from '../../assets/images/Tracker/track6.svg';
import {NavLink} from "react-router-dom";
import {TrackerWheelContext} from "../../context/trackerContext";


const Wheel = () => {
    const [Canvas, setCanvas] = useState({});

    const canvas = useRef();
    const wheel = useRef();

    const {
        calculateAnswer,
        setIsCompleted,
        isCompleted
    } = useContext(TrackerWheelContext)

    useEffect(() => {
        const wheel = new wheelOfLife(areas, {
            levels: 10,
            maxFontSize: 20,
            onCompleted: (result) => {
                calculateAnswer(result);
                setIsCompleted(true);
            }
        }, canvas);
        setCanvas(wheel);
    }, [])


    return (
        <div className="tracker">
            <img className="decor-footer" src={decorFooter} alt=""/>
            <div className="tracker__logo">
                <img src={logoFinance} alt=""/>
            </div>

            <NavLink to="/" className="back tracker__back">
                <img src={backArrow} alt=""/>
                <span>Меню</span>
            </NavLink>

            <div className="tracker__info">
                <h1 className="tracker__info-title">Трекер ФинЗОЖ</h1>
                <p className="tracker__info-text">Давайте разложим все по полочкам:</p>
                <p className="tracker__info-text"><span>1 балл</span> – плохо, не уверен в себе</p>
                <p className="tracker__info-text"><span>10 баллов</span> – все супер, так и делаю</p>
            </div>
            <div className="tracker__body">
                <div className="tracker__item">
                    <div ref={wheel} className='panel_body wheel'>
                        <div style={{display: 'none'}} className='position-relative'>
                            <div className='hint'></div>
                        </div>
                        <canvas ref={canvas} id='canvas' className="canvas-wheel"></canvas>
                    </div>
                    <div className="tracker__tip track1">
                        <div className="tracker__tip-text">
                            Как вы планируете доходы и расходы? 1 – живу одним днем, 10 – планирую
                            вдолгую и четко следую плану
                        </div>
                        <div className="tracker__tip-icon">
                            <img src={track1} alt=""/>
                        </div>
                    </div>
                    <div className="tracker__tip track2">
                        <div className="tracker__tip-text">
                            Вы пользуетесь бонусами и кешбэком от государства и коммерческих структур? 1 –
                            ничего о них не знаю, 10 – пользуюсь всем, чем только возможно
                        </div>
                        <div className="tracker__tip-icon">
                            <img src={track2} alt=""/>
                        </div>
                    </div>
                    <div className="tracker__tip track3">
                        <div className="tracker__tip-text">
                            У вас есть кредиты и долги? 1 – большую часть дохода трачу на погашение
                            обязательств, 10 – у меня совсем нет долгов
                        </div>
                        <div className="tracker__tip-icon">
                            <img src={track3} alt=""/>
                        </div>
                    </div>
                    <div className="tracker__tip track4">
                        <div className="tracker__tip-text">
                            Вы транжира? 1 – трачу деньги быстро и не понимаю куда, 10 – я, скорее, скряга
                        </div>
                        <div className="tracker__tip-icon">
                            <img src={track4} alt=""/>
                        </div>
                    </div>
                    <div className="tracker__tip track5">
                        <div className="tracker__tip-text">
                            Вы откладываете деньги на случай потери работы или непредвиденных расходов? 1 –
                            нет, 10 – накопленных средств хватит на то, чтобы жить без дохода несколько
                            месяцев
                        </div>
                        <div className="tracker__tip-icon">
                            <img src={track5} alt=""/>
                        </div>
                    </div>
                    <div className="tracker__tip track6">
                        <div className="tracker__tip-text">
                            Вы вкладываете свободные средства для получения дополнительного дохода? 1 – нет,
                            10 – постоянно инвестирую свои деньги
                        </div>
                        <div className="tracker__tip-icon">
                            <img src={track6} alt=""/>
                        </div>
                    </div>
                </div>
                {isCompleted &&
                    <div className="result-window">
                        <p className="result-window__bold-text">На что вам следует обратить внимание, чтобы финансы
                            были в балансе.</p>
                        <p className="result-window__text">Хотите узнать подробности?</p>
                        <NavLink className="result-window__btn" to="/tracker/result">ДА</NavLink>
                    </div>
                }
            </div>
        </div>
    );
};

export default Wheel;